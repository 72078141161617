import { useQuery } from "react-query";
import { API } from "../../api";
import { Control, useWatch } from "react-hook-form";
import { passportAndInnFormType } from "../../components/pages/Application/pages/PassportAndInn/types";
import { useEffect, useState } from "react";
import { selectItemType } from "../../components/ui/Selects/Select";
import { bankType } from "../../api/main/application/types";

// export const useGetIssuedBy = ({}: Control<passportAndInnFormType, object>) => {
export const useGetIssuedBy = ({ control, setValue }: any) => {
  const [transformedDataForSelect, setTransformedDataForSelect] = useState<
    Array<selectItemType<any>>
  >([]);
  const [issuedByValue, setIssuedByValue] = useState("");

  const divisionCode = useWatch({
    control: control,
    name: "passport.division_code",
  });

  const issuedBy = useWatch({
    control: control,
    name: "passport.issued_by",
  });
  
  const getIssuedBy: any = useQuery(
    ["getIssuedBy", divisionCode, issuedBy],
    () => {
      if (divisionCode?.length === 7 && !issuedBy) {
        return API.main.services.getDivisionCode({
          code: divisionCode,
        });
      }
    },
    {
      onSuccess: (res: any) => {
        if (res?.status === 200) {
          setTransformedDataForSelect(
            res?.data?.data?.suggestions.map((item: any, index: number) => {
              return {
                id: index,
                text: item,
                value: item,
              };
            })
          );
        }
      },
    }
  );

  // useEffect(() => {
  //   if (getIssuedBy?.data?.data?.data?.suggestions?.length === 1) {
  //     setIssuedByValue(getIssuedBy?.data?.data?.data?.suggestions?.[0]);
  //   }
  // }, [getIssuedBy?.data]);

  return {
    isLoading: getIssuedBy?.isLoading,
    issuedBy: getIssuedBy?.data?.data?.data?.suggestions,
    transformedDataForSelect,
    issuedByValue,
    setIssuedByValue,
  };
};
