import { PayloadAction } from "@reduxjs/toolkit";
import { processStateController } from "../../util/helpers/processStateController";
import { call, put } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { sagaApiType } from "../../util/types";
import { API } from "../../../api";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { lsController } from "../../../store/auxiliary/localStorage";
import { applicationReducer } from "../../../store/reducers/application";
import {
  createApplicationType,
  postApplicationType,
} from "../../../api/main/application/types";
import { capitalize } from "../../../components/pages/Application/pages/FioAndGrz";
import { sendMetrics } from "../../../metrics";

export function* postApplicationSaga({
  payload,
  type: actionType,
}: PayloadAction<postApplicationType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    if (payload.vin_or_reg_number) {
      const response: sagaApiType = yield call(
        API.main.application.postApplication,
        {
          vin_or_reg_number: payload.vin_or_reg_number,
          user_info: {
            first_name: payload.user_info.first_name,
            middle_name: payload?.user_info.middle_name,
            last_name: payload.user_info.last_name,
            birth_day: payload.user_info.birth_day,
            gender: payload.user_info.gender,
          },
          loan_data: {
            amount: payload.loan_data.amount,
            loan_term: payload.loan_data.loan_term,
          },
          email: payload.email,
          monthly_income: payload.monthly_income,
        }
      );

      if (response.status === 201) {
        sendMetrics("common-client_sozdanie-zayavki_common-app", "sozdanie-zayavki");

        lsController.set("application_id", response.data?.data?.id);

        yield put(
          applicationReducer.actions.setApplicationId({
            application_id: response.data?.data?.id,
          })
        );

        yield redirect(routes.application.statuses.data_verification);
      }
    } else {
      yield redirect(routes.auth.phone);
    }
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
      handledStatuses: [400],
    });
  } finally {
    yield process.stop();
  }
}
