import { call, put, select, takeEvery } from "redux-saga/effects";
import { createAction } from "@reduxjs/toolkit";
import { UserRoleType } from "../../../types/user/types";
import { userReducerSelectors } from "../../../store/reducers/user/selectors";
import { redirect } from "./redirect";
import { routes } from "../../../routes";
import { sagaApiType } from "../types";
import { API } from "../../../api";
import { errorHandlerSaga } from "./errorHandlerSaga";
import { applicationType } from "../../../types/application/types";
import { applicationReducer } from "../../../store/reducers/application";
import { authSagaActions } from "../../auth/actions";
import { setProcessLoading } from "../../../store/auxiliary/loadingReducer";
import { StateType } from "../../../store";
import { lsController } from "../../../store/auxiliary/localStorage";
import { userRedirect } from "../../../helpers/routes";
import { isMobile } from "react-device-detect";
import { loanReducer } from "../../../store/reducers/loan";
import { sagaActions } from "../sagaActions";
import { loanType } from "../../../types/cabinet/types";
import { sendMetrics } from "../../../metrics";

// * сага нужна для того, чтобы определять можно ли челу оставаться на том же урле или редиректнуть его

// https://stg01.creddy.ru/user/phone?amount=500000&period_month=36&phone=+7%20(222)%20222-22-00

const isMobileFromDeviceDetect: boolean =
  process.env.NODE_ENV === "development" ? true : isMobile;

export function* routingSaga() {
  console.log("routing action saga");
  console.log("isMobile", isMobile);
  // * доработать потом, чтобы принимал тип
  const actionType = authSagaActions.autoAuth.type;
  const actionType2 = authSagaActions.getToken.type;
  const actionType3 = authSagaActions.getCar.type;
  const actionType4 = authSagaActions.refreshToken.type;
  try {
    yield put(setProcessLoading("routing", true));

    const user_role: UserRoleType = yield select(
      userReducerSelectors.getUserRole
    );

    const applications: sagaApiType = yield call(
      API.main.application.getApplications
    );

    yield put(
      applicationReducer.actions.setApplicationDocuments({
        document_photos: applications?.data?.data?.items[0]?.document_photos,
      })
    );

    const location: string = yield select(
      (state: StateType) => state.router.location.pathname
    );

    const loans: sagaApiType = yield call(API.main.application.getLoan, {});

    const vinOrRegNumber = lsController.get("grz") || lsController.get("vin");

    if (location === "/info") {
      yield redirect(routes.info);
      return;
    }

    switch (user_role) {
      case "user":
        {
          /*
          сначала чекаем, есть ли у юзера займы
          есть - кидаем в лк на любом девайсе
          если займов нет - пропускаем логику до заявок
         */
          // const loans: sagaApiType = yield call(
          //   API.main.application.getLoan,
          //   {}
          // );

          // const applications: sagaApiType = yield call(
          //   API.main.application.getApplications
          // );

          // * тут кидаем запрос на все заявки
          // TODO аналогично с кодом ниже, возможно запрос лишний - посмотреть на поведение, выпилить
          // const applications: sagaApiType = yield call(
          //   API.main.application.getApplications
          // );

          // const loan = loans?.data?.data.items_count > 0 ? loans?.data?.data.items[0] : {};
          const loan = loans?.data?.data.items?.[0];

          if (
            vinOrRegNumber &&
            (location === "/user/restore_password" ||
              location === "/user/password" ||
              location === "/user/personal_cabinet_password") &&
            applications.data.data.items_count === 0
          ) {
            const response: sagaApiType = yield call(
              API.main.application.createApplicationShort,
              { vin_or_reg_number: vinOrRegNumber }
            );

            if (response.status === 201) {
              yield put(
                applicationReducer.actions.setApplicationId({
                  application_id: response.data?.data?.id,
                })
              );
              yield put(
                applicationReducer.actions.setApplicationDocuments({
                  document_photos: response?.data?.data?.document_photos,
                })
              );

              yield redirect(routes.application.documents);
              return;
            }
          }

          /*
          Если займ упал в ошибку (в статусе не issued_loan),
           мы разрешаем юзеру заполнить новую заявку
           Если статус ок - редиректим на лк на любом девайсе
         */

          // временный костыль для взятия нового займа
          //TODO
          // if (loan?.status === "paid_loan") {
          //   yield redirect(routes.auth.vehicle_valuation);
          // }

          if (
            loan?.status === "issued_loan" ||
            loan?.status === "overdue_loan"
          ) {
            yield put(
              loanReducer.actions.setLoanId({
                loan_id: loan?.id,
              })
            );
            yield put(
              applicationReducer.actions.setApplicationId({
                application_id: loan?.application_id,
              })
            );
            // * если мы в лк, то сверяем по доступным урлам, если всё ок, то оставляем на месте, если нет, то перекидываем
            const location: string = yield select(
              (state: StateType) => state.router.location.pathname
            );

            if (location === "/info") {
              yield redirect(routes.info);
              return;
            }

            const allowedCabinetPages = Object.values(routes.cabinet);
            if (!allowedCabinetPages.includes(location)) {
              yield redirect(routes.cabinet.my_loan);
            }

            return;
          }
        }

        // заявка - только на мобиле
        if (!isMobile) {
          yield redirect(routes.auth.to_mobile_device);
          return;
        }

        // * тут кидаем запрос на все заявки
        //TODO возможно запрос не нужен, если посыпятся баги - исправить
        // const applications: sagaApiType = yield call(
        //   API.main.application.getApplications
        // );

        const applicationsItems = applications?.data?.data?.items;

        lsController.set("application_id", applicationsItems[0]?.id);

        // * length = 0 - то кидаем на стартовую страницу просто
        // if (!applicationsItems.length) {
        //   yield redirect(routes.auth.vehicle_valuation);
        //   return;
        // }

        // * 1 и больше - то фильтруем по определенным статусам заявки
        const unfinishedApplications: applicationType[] =
          applicationsItems.filter(
            ({ application_type }: applicationType) =>
              application_type !== "issued_final" &&
              application_type !== "refusal_final" &&
              application_type !== "user_refused_final" &&
              application_type !== "error_final"
          );

        const applicationType = applicationsItems.find(
          ({ application_type }: applicationType) => application_type
        )?.application_type;

        // * если отфильтрованных нет, то кидаем в лк или если он находился в лк, то решаем оставить его или нет
        if (!unfinishedApplications.length) {
          const finishedApplications: applicationType[] =
            applicationsItems.filter(
              ({ application_type }: applicationType) =>
                application_type === "issued_final"
            );

          const loanArray = loans?.data?.data?.items;
          const lastLoanStatus = loanArray[loanArray?.length - 1]?.status;
          const items_count: number = loans.data.data.items_count;
          const statusesErrorForSendMetrics = [
            "refusal_final",
            "error_final",
            "sbp_check_error",
          ];

          const successLoans = loanArray.filter(
            (item: loanType) =>
              item.status !== "error_loan" && item.status !== "cancelled_loan"
          );

          yield put(
            loanReducer.actions.setOnlyErrorLoans({
              onlyErrorLoans: successLoans.length <= 0,
            })
          );

          if (
            !unfinishedApplications.length &&
            loanArray.length &&
            !successLoans.length
          ) {
            lsController.set("grz", "");
            lsController.set("vin", "");
          }

          if (
            !unfinishedApplications.length &&
            lastLoanStatus === "paid_loan"
          ) {
            yield put(applicationReducer.actions.setItemsCount(items_count));
            yield put(applicationReducer.actions.setLastStatus(lastLoanStatus));
            yield redirect(routes.cabinet.paid_loan);
            return;
          }

          // поменял finished на UN finished, потом вернуть
          if (
            !unfinishedApplications.length &&
            (items_count === 0 ||
              lastLoanStatus === "paid_loan" ||
              !successLoans.length)
          ) {
            console.log(lastLoanStatus, "lastLoanStatus");
            console.log("create app short");
            yield put(applicationReducer.actions.setItemsCount(items_count));
            yield put(applicationReducer.actions.setLastStatus(lastLoanStatus));
            yield redirect(routes.application.fio_and_grz);
            if (statusesErrorForSendMetrics?.includes(applicationType)) {
              sendMetrics("common-client_login-v-lk_repeat-app", "login-v-lk");
            } else {
              sendMetrics("common-client_login-v-lk_new-app", "login-v-lk");
            }

            // TODO возможно это нужно перенести после выбора банка (Уточнить)
            // yield put(sagaActions.application.createApplicationShort());
            return;
            // const response: sagaApiType = yield call(
            //   API.main.application.createApplicationShort
            // );

            // if (response.status === 201) {
            //   yield put(
            //     applicationReducer.actions.setApplicationId({
            //       application_id: response.data?.data?.id,
            //     })
            //   );
            //
            //   yield put(
            //     applicationReducer.actions.setApplicationDocuments({
            //       document_photos: response.data?.data?.document_photos,
            //     })
            //   );
            //
            //   yield redirect(routes.application.documents);
            //   return;
            // }
          }
          // TODO
          // } else if (
          //   !unfinishedApplications.length &&
          //   loans?.data?.data.items?.[0]?.status === "paid_loan"
          // ) {
          //   yield redirect(routes.auth.vehicle_valuation);
          //   return;
          // }

          /*
            Повтор логики для чела с открытым займом
            Скорее всего она не нужна вообще здесь второй раз - надо подумать и выпилить
            Но без нее не отрабатывает как надо
           */
          if (location === "/info") {
            yield redirect(routes.info);
            return;
          }

          const allowedCabinetPages = Object.values(routes.cabinet);
          if (!allowedCabinetPages.includes(location)) {
            console.log("to my loan");
            yield redirect(routes.cabinet.my_loan);
          }
          return;
        }

        const statusesForSendMetricsFromNewToSigningFilling = [
          "new",
          "passport_filling",
          "inn_check_process",
          "survey_filling",
          "signing_applying_agreement_filling",
          "issued_final",
          "refusal_final",
          "error_final",
        ];

        // * если отфильтрованная есть, то закидываем id первой заявки в стор, смотрим на статус и кидаем на нужную страницы заявки
        yield put(
          applicationReducer.actions.setApplicationId({
            application_id: unfinishedApplications[0]?.id,
          })
        );

        yield put(
          applicationReducer.actions.setApplicationType({
            application_type: unfinishedApplications[0]?.application_type,
          })
        );
        yield redirect(
          userRedirect(
            unfinishedApplications[0].application_type,
            unfinishedApplications[0]?.error_type
          )
        );
        if (
          statusesForSendMetricsFromNewToSigningFilling?.includes(
            applicationType
          )
        ) {
          sendMetrics("common-client_login-v-lk_repeat-app", "login-v-lk");
        } else {
          sendMetrics("common-client_login-v-lk_common-app", "login-v-lk");
        }
        break;
    }
  } catch (e) {
    yield call(errorHandlerSaga, {
      response: e,
    });
  } finally {
    yield put(setProcessLoading(actionType, false));
    yield put(setProcessLoading(actionType2, false));
    yield put(setProcessLoading(actionType3, false));
    yield put(setProcessLoading(actionType4, false));
    yield put(setProcessLoading("routing", false));
  }
}

export const routingAction = createAction("routing");

export function* routingWatcherSaga() {
  yield takeEvery(routingAction, routingSaga);
}
