import React, {
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { Control, useForm, useWatch } from "react-hook-form";
import { passportAndInnFormType } from "./types";
import { SmartForm } from "../../../../hocs/SmartForm";
import { InputEditable } from "../../../../ui/Inputs/InputEditable";
import { SmartCalendar } from "../../../../ui/Calendar";
import { Switcher } from "../../../../ui/Switcher";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { Button } from "../../../../ui/Button";
import { Loader } from "../../../../ui/Loader";
import { useGetInfoFromDocuments } from "../../../../../hooks/api/useGetInfoFromDocuments";
import { RoundContainer } from "../../../../ui/Containers/RoundContainer";
import { MessageBox } from "../../../../ui/MessageBox";
import { Icons } from "assets/icons";
import {
  GetAddressSelect,
  getRelevantFias,
} from "../../../../ui/Selects/GetAddressSelect";
import get from "lodash/get";
import { InputNumber } from "../../../../ui/Inputs/InputNumber";
import { passportAndInnSchema } from "./shema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useChangeFocus } from "../../../../../hooks/logical/useChangeFocus";
import { useGetIssuedBy } from "../../../../../hooks/api/useGetIssuedBy";
import cn from "classnames";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { validateInn } from "../../../../../helpers/inn";
import { showYMDDate } from "../../../../../helpers/date";
import { stringHelpers } from "../../../../../helpers/string";
import { BannerWhite } from "../../../../ui/BannerWhite";
import { sendMetrics } from "../../../../../metrics";
import { ReactComponent as TransparentRoundCheckmarkBlue } from "../../../../../assets/icons/ui/TransparentRoundCheckmarkBlue.svg";
import { FillingAssistance } from "../../../../ui/FillingAssistance";
import { PassportBanner } from "./PassportBanner";
import { InputText } from "../../../../ui/Inputs/InputText";
import { capitalize } from "../FioAndGrz";
import { routes } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import useTrackTabClose from "../../../../../metrics/customHooks/useTrackTabClose";
import { Select } from "../../../../ui/Selects/Select";
import { setValues } from "framer-motion/types/render/utils/setters";

export const PassportAndInn: FC = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const seriesRef = useRef<HTMLInputElement>(null);
  const numberRef = useRef<HTMLInputElement>(null);
  const calendarRef = useRef<HTMLInputElement>(null);
  const divisionCodeRef = useRef<HTMLInputElement>(null);
  const addressRegistrationRef = useRef<HTMLInputElement>(null);
  const addressActualRef = useRef<HTMLInputElement>(null);
  const birthPlaceRef = useRef<HTMLInputElement>(null);

  const loading = useSelector(
    processLoading.get(
      applicationSagaActions.addingPassportAndInnToApplication.type
    )
  );

  const routeLoading = useSelector(processLoading.get("routing"));

  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const wasOptionalPhotoRecognized = lsController.get(
    "wasOptionalPhotoRecognized"
  );

  const form = useForm<passportAndInnFormType>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(passportAndInnSchema),
    context: { dateInputValue: calendarRef?.current?.value },
    defaultValues: {
      // * это для теста было и для примера валидации
      // passport: {
      //   gender: false,
      //   series: "1111",
      //   number: "222222",
      //   birth_day: "1990-08-05",
      //   issue_date: "2010-08-05",
      //   division_code: "111111",
      //   issued_by: "УФМС РОССИИ",
      //   birth_place: "г Новосибирск, ул Урицкого, д 10",
      //
      //   first_name: "Пересвет",
      //   middle_name: "Пересветович",
      //   last_name: "Светопер",
      // },
      // inn: "362504340253",
      //  4200000..000
      //  11/25 123
    },
  });

  // const innWatch = useWatch({
  //   control: form.control,
  //   name: "inn",
  // });

  // console.log("  const innWatch = useWatch({\n", innWatch);

  // const [innLength, setInnLength] = useState(false);

  // let innLength = false;
  // let validInn = null;
  // if (innWatch && innWatch?.length < 12) {
  //   innLength = true;
  // } else if (innWatch && innWatch?.length === 12) {
  //   validInn = innWatch && validateInn(undefined ? "" : innWatch);
  // }

  const [
    addressMatch,
    selectedIssuedBy,
    Code,
    issuedDate,
    secondPhone,
    birthPlace,
    passportSeries,
    passportNumber,
    registrationAddress,
    actualAddress,
  ] = useWatch({
    control: form.control,
    name: [
      "address_match",
      "passport.issued_by",
      "passport.division_code",
      "passport.issue_date",
      "second_phone",
      "passport.birth_place",
      "passport.series",
      "passport.number",
      "registration_address",
      "actual_address",
    ],
  });

  const isAllFieldsFilled = !!(
    passportSeries && passportNumber && issuedDate && Code && registrationAddress && birthPlace
  );

  // useEffect(() => {
  //   if (process.env.REACT_APP_ENVIRONMENT == "stage") {
  //     form.setValue("passport.series", "4242");
  //     form.setValue("passport.number", "424242");
  //     form.setValue("passport.issue_date", "2011-01-18");
  //     form.setValue("passport.division_code", "424-242");
  //     form.setValue("passport.birth_place", "г. Воронеж");
  //   }
  // }, []);

  useEffect(() => {
    if (Code && Code?.length <= 7) {
      form.setValue("passport.issued_by", "");
    }
  }, [Code]);

  // * хук делает 2 запроса на данные с 2 фоток паспорта
  // * потом сетит нужные значения в форму
  const {
    inn,
    registration_address,
    isLoading,
    firstName,
    middleName,
    lastName,
  } = useGetInfoFromDocuments(form.setValue);

  const fullName = lsController.get("full_name");

  const fullNameFromPhoto = lastName + " " + firstName + " " + middleName;

  const fullNameNormalized = fullName?.replace(/\s/g, "").toLowerCase();
  const fullNameFromPhotoNormalized = fullNameFromPhoto
    ?.replace(/\s/g, "")
    .toLowerCase();

  const {
    isLoading: isIssuedByLoading,
    transformedDataForSelect,
    issuedByValue,
    setIssuedByValue,
  } = useGetIssuedBy({
    control: form.control,
    setValue: form.setValue,
  });

  const submit = (data: passportAndInnFormType) => {
    const registration_address = {
      full_address: data?.registration_address?.text,
      fias: getRelevantFias(data?.registration_address?.value?.data) || "",
    };

    dispatch(
      applicationSagaActions.addingPassportAndInnToApplication({
        applicationId,
        // inn: data?.inn || "",
        passport: {
          ...data.passport,
          issue_date: moment(issuedDate).format("YYYY-MM-DD"),
          issued_by: selectedIssuedBy?.value,
          birth_place: birthPlace?.trim() || "",
          division_code: stringHelpers.clearString(
            data.passport?.division_code || ""
          ),
        },

        registration_address: registration_address,
        actual_address: data?.address_match
          ? registration_address
          : {
              full_address: data?.actual_address?.text,
              fias: getRelevantFias(data?.actual_address?.value?.data) || "",
            },
        // monthly_income: +String(data.monthly_income)?.replaceAll(" ", ""),
        // trusted_person_phone: secondPhone
        //   .replaceAll("+", "")
        //   .replaceAll(" ", ""),
      })
    );
  };

  const [isCodeInputFocused, setIsCodeInputFocused] = useState(false);

  useEffect(() => {
    sendMetrics("anketa_start");
  }, []);

  useEffect(() => {
    const seriesLength = form.watch("passport.series")?.length || 0;
    if (seriesRef.current && seriesLength === 4) {
      numberRef.current?.focus();
    } else if (seriesLength === 0) {
      seriesRef.current?.focus();
    }
  }, [form.watch("passport.series")]);

  useEffect(() => {
    const numberLength = form.watch("passport.number")?.length || 0;
    if (numberRef.current && numberLength === 6) {
      calendarRef.current?.focus();
    } else if (numberLength === 0) {
      seriesRef.current?.focus();
    }
  }, [form.watch("passport.number")]);

  useEffect(() => {
    const issueDateLength = form.watch("passport.issue_date")?.length || 0;
    if (issueDateLength === 0) {
      numberRef.current?.focus();
    }
  }, [form.watch("passport.issue_date")]);

  useTrackTabClose("common-client_leave-LK_common-app", "leave-LK");

  const { keyPressHandler, setCurrentField } = useChangeFocus(
    [
      seriesRef,
      numberRef,
      calendarRef,
      divisionCodeRef,
      addressRegistrationRef,
      ...!addressMatch ? [addressActualRef] : [],
      birthPlaceRef,
    ],
    isAllFieldsFilled
  );

  const iconResolverForCodeDivision = () => {
    if (isIssuedByLoading) {
      return (
        <Loader className={styles.loader} color={"black"} variant={"medium"} />
      );
    } else if (selectedIssuedBy) {
      return <Icons.ui.PencilBlack />;
    } else {
      return null;
    }
  };

  useEffect(() => {
    addressMatch && registrationAddress?.text && birthPlaceRef.current && birthPlaceRef.current.focus();
  }, [registrationAddress?.text]);

  useEffect(() => {
    actualAddress?.text && birthPlaceRef.current && birthPlaceRef.current.focus();
  }, [actualAddress?.text]);

  return (
    <MobilePageContainer
      className={cn(styles.form, {
        [styles.form_no_padding]: isLoading,
      })}
    >
      {/*{isLoading || !applicationId ? (*/}
      {isLoading || !applicationId ? (
        <Loader color={"purple"} variant={"large"} className={styles.loader} />
      ) : (
        <>
          <SmartForm form={form} submit={submit}>
            {!wasOptionalPhotoRecognized && (
              <div
                className={styles.header_photo_container}
                onClick={() => history.push(routes.application.documents)}
              >
                <div className={styles.header_photo_text}>
                  Если хотите, мы можем распознавать фото паспорта
                </div>
                <div className={styles.container_img}>
                  <Icons.ui.CameraBlue />
                </div>
              </div>
            )}

            {wasOptionalPhotoRecognized &&
              fullNameNormalized === fullNameFromPhotoNormalized && (
                <div className={styles.header_title}>
                  Проверьте распознанные данные
                </div>
              )}

            {wasOptionalPhotoRecognized &&
              fullNameNormalized !== fullNameFromPhotoNormalized && (
                <>
                  <div className={styles.header_title}>
                    ФИО с паспорта неверное <br /> или некорректно распознано
                  </div>
                  <div className={styles.main_title}>
                    Продолжите ручное заполнение
                  </div>
                </>
              )}

            {/*{wasOptionalPhotoRecognized && (*/}
            {/*  <div className={styles.header_title}>*/}
            {/*    Проверьте распознанные данные*/}
            {/*  </div>*/}
            {/*)}*/}

            {/*<RoundContainer className={styles.fio_grid}>*/}
            {/*  <InputEditable*/}
            {/*    name={"passport.last_name"}*/}
            {/*    label={"Фамилия"}*/}
            {/*    className={styles.fullName}*/}
            {/*  />*/}
            {/*  <InputEditable*/}
            {/*    name={"passport.first_name"}*/}
            {/*    label={"Имя"}*/}
            {/*    className={styles.fullName}*/}
            {/*  />*/}
            {/*  <InputEditable*/}
            {/*    name={"passport.middle_name"}*/}
            {/*    label={"Отчество"}*/}
            {/*    className={styles.fullName}*/}
            {/*  />*/}

            {/*  <SmartCalendar*/}
            {/*    name={"passport.birth_day"}*/}
            {/*    placeholder={"Дата рождения"}*/}
            {/*  />*/}

            {/*<InputEditable*/}
            {/*  name={"passport.birth_place"}*/}
            {/*  label={"Место рождения"}*/}
            {/*  className={styles.fullName}*/}
            {/*/>*/}

            {/*  <Switcher*/}
            {/*    name={"passport.gender"}*/}
            {/*    className={styles.gender}*/}
            {/*    outerLabel={"Пол"}*/}
            {/*    innerLabels={["Мужской", "Женский"]}*/}
            {/*    switchVariant={"huge_gender"}*/}
            {/*    variant={"large"}*/}
            {/*  />*/}
            {/*</RoundContainer>*/}

            <RoundContainer className={styles.passport_grid}>
              <div className={styles.row}>
                <InputNumber
                  inputElementRef={seriesRef}
                  type={"tel"}
                  support={
                    wasOptionalPhotoRecognized ? <Icons.ui.PencilBlack /> : null
                  }
                  name={"passport.series"}
                  label={"Серия"}
                  min={4}
                  max={4}
                  maxLength={4}
                  decimalScale={0}
                  autoFocus
                  onFocus={() => setCurrentField("passport.series")}
                  onKeyDown={keyPressHandler}
                />
                <InputNumber
                  inputElementRef={numberRef}
                  type={"tel"}
                  support={
                    wasOptionalPhotoRecognized ? <Icons.ui.PencilBlack /> : null
                  }
                  name={"passport.number"}
                  label={"Номер"}
                  max={6}
                  min={6}
                  maxLength={6}
                  decimalScale={0}
                  onFocus={() => setCurrentField("passport.number")}
                  onKeyDown={keyPressHandler}
                />
              </div>

              <SmartCalendar
                name={"passport.issue_date"}
                placeholder={"Дата выдачи паспорта"}
                inputRef={calendarRef as MutableRefObject<HTMLInputElement>}
                onKeyDown={keyPressHandler}
                onFocus={() => setCurrentField("passport.issue_date")}
              />

              {/* Кем выдан паспорт получаем по GET /passport/division_code/{code} */}
              {/*<MessageBox*/}
              {/*  icon={<Icons.ui.TransparentRoundCheckmarkBlue />}*/}
              {/*  isLoading={isIssuedByLoading}*/}
              {/*  loadingMessage={"Определяется орган, выдавший документ"}*/}
              {/*>*/}
              {/*  {issuedBy || "Заполните код подразделения"}*/}
              {/*</MessageBox>*/}
            </RoundContainer>

            <RoundContainer className={styles.passport_grid}>
              <div
                className={cn(styles.division_code_and_issued_by_container, {
                  [styles.division_code_and_issued_by_container_without_padding]:
                    selectedIssuedBy?.value,
                })}
              >
                <InputText
                  onFocus={() => {
                    setIsCodeInputFocused(true);
                    setCurrentField("passport.division_code");
                  }}
                  onBlur={() => setIsCodeInputFocused(false)}
                  onKeyDown={keyPressHandler}
                  support={iconResolverForCodeDivision()}
                  name={"passport.division_code"}
                  label={"Код подразделения"}
                  inputMode={"numeric"}
                  mask={"999-999"}
                  inputElementRef={divisionCodeRef}
                />
                {selectedIssuedBy?.value || issuedByValue ? (
                  <div className={styles.selected_issued_by_value}>
                    {selectedIssuedBy?.value || issuedByValue}
                  </div>
                ) : (
                  <div className={styles.text_division_code_and_issued_by}>
                    Начните вводить код и выберите нужное из списка
                  </div>
                )}

                <Select
                  isCodeInputFocused={isCodeInputFocused}
                  division_code={Code}
                  className={cn(styles.select_issued_by, {
                    [styles.select_issued_by_for_selected_value_short]:
                      selectedIssuedBy?.value &&
                      selectedIssuedBy?.value?.length < 30,
                    [styles.select_issued_by_for_selected_value_long]:
                      selectedIssuedBy?.value &&
                      selectedIssuedBy?.value?.length > 30,
                  })}
                  name={"passport.issued_by"}
                  control={form.control as Control<any>}
                  setValue={form.setValue}
                  options={transformedDataForSelect}
                  selectTitle={"Кем выдан"}
                  search
                  support={<Icons.ui.DropDown />}
                />
              </div>
            </RoundContainer>

            {/* * ошибки можем доставать из 2 полей внутри объекта адреса */}
            <GetAddressSelect
              control={form.control as any}
              label={"Адрес регистрации"}
              name={"registration_address"}
              setFocus={!!selectedIssuedBy?.value}
              setValue={form.setValue}
              error={
                get(form.formState.errors, "registration_address")?.text
                  ?.message ||
                (get(form.formState.errors, "registration_address")?.value?.data
                  ?.house?.message as any)
              }
              clearErrors={form.clearErrors}
              defaultValue={registration_address}
              inputElementRef={addressRegistrationRef}
              keypressHandler={keyPressHandler}
              onFocus={() => setCurrentField("street-address")}
            />
            <div className={styles.text_division_code_and_issued_by}>
              Начните вводить адрес и выберите нужное из списка
            </div>
            <div className={styles.address_match}>
              <div className={styles.address_text}>
                Адрес фактического проживания совпадает <br />с адресом
                регистрации
              </div>
              <Switcher defaultChecked={true} name={"address_match"} />
            </div>
            {!addressMatch && addressMatch !== undefined && (
              <>
                <GetAddressSelect
                  name={"actual_address"}
                  label={"Адрес фактического проживания"}
                  setFocus={!!(registrationAddress?.text && addressMatch === false)}
                  setValue={form.setValue}
                  control={form.control as any}
                  error={
                    get(form.formState.errors, "actual_address")?.text?.message ||
                    (get(form.formState.errors, "actual_address")?.value?.data
                      ?.house?.message as any)
                  }
                  clearErrors={form.clearErrors}
                  inputElementRef={addressActualRef}
                  keypressHandler={keyPressHandler}
                  onFocus={() => setCurrentField("street-address")}
                />
                <div className={styles.text_division_code_and_issued_by}>
                  Начните вводить адрес и выберите нужное из списка
                </div>
              </>
            )}
            <InputText
              name={"passport.birth_place"}
              label={"Место рождения как в паспорте"}
              className={styles.fullName}
              support={<Icons.ui.PencilBlack />}
              inputElementRef={birthPlaceRef}
            />

            {/* * если инн с бека пришёл, то мы его не отображаем юзеру */}
            {/*{!inn && (*/}
            {/*  <InputNumber*/}
            {/*    innLength={innLength}*/}
            {/*    validInn={validInn !== null ? validInn : true}*/}
            {/*    name={"inn"}*/}
            {/*    label={"ИНН"}*/}
            {/*    maxLength={12}*/}
            {/*  />*/}
            {/*)}*/}

            {/*<InputNumber*/}
            {/*  inputMode={"numeric"}*/}
            {/*  support={<Icons.ui.Ruble data-class={"dim"} />}*/}
            {/*  thousandsGroupStyle={"thousand"}*/}
            {/*  label={"Средний месячный доход"}*/}
            {/*  name={"monthly_income"}*/}
            {/*  min={0}*/}
            {/*  max={1000000}*/}
            {/*  maxLength={9}*/}
            {/*/>*/}
            <Button className={styles.button} loading={loading || routeLoading}>
              Продолжить
            </Button>
            {/*<FillingAssistance />*/}
          </SmartForm>
        </>
      )}
    </MobilePageContainer>
  );
};
