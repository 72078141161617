import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { useForm, useWatch } from "react-hook-form";
import { Icons } from "assets/icons";
import { Select, selectItemType } from "../../../../ui/Selects/Select";
import { SBPSupportBlock } from "../AccountConfirmation/SBPSupportBlock";
import { RoundContainer } from "../../../../ui/Containers/RoundContainer";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { Button } from "../../../../ui/Button";
import { SmartForm } from "../../../../hocs/SmartForm";
import { Radio } from "../../../../ui/Radio";
import { useGetBanks } from "./useGetBanks";
import { Loader } from "../../../../ui/Loader";
import { bankType } from "../../../../../api/main/application/types";
import { API } from "../../../../../api";
import { useDispatch, useSelector } from "react-redux";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { routes } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { RangeWithTitle } from "../../../../ui/RangeWithTitle";
import { monthRangeData } from "../NewLoan";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { sendMetrics } from "../../../../../metrics";
import { FillingAssistance } from "../../../../ui/FillingAssistance";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { InputNumber } from "../../../../ui/Inputs/InputNumber";
import { useGetInfoFromDocuments } from "../../../../../hooks/api/useGetInfoFromDocuments";
import { validateInn } from "../../../../../helpers/inn";
import { ReactComponent as VTBBanck } from "*.svg";
import { ReactComponent as OtkritieBank } from "../../../../../assets/icons/logos/OtkritieBank.svg";
import { Clock } from "../../../../ui/Clock";
import { VideoCallTimeFormType } from "../Statuses/SentAndScoringProcess";
import { stringHelpers } from "../../../../../helpers/string";
import { useClock } from "../../../../../hooks/logical/useClock";
import { useGetInfoFromApplication } from "../../../../../hooks/api/useGetInfoFromApplication";
import { yupResolver } from "@hookform/resolvers/yup";
import { fioAndGrzSchema } from "../FioAndGrz/shema";
import { SBPDefineProcessSchema } from "./shema";
import { useDebounce } from "../../../../../hooks/logical/useDebounce";
import { useChangeFocus } from "../../../../../hooks/logical/useChangeFocus";
import { sagaActions } from "../../../../../sagas/util/sagaActions";
import { InputText } from "../../../../ui/Inputs/InputText";
import useTrackTabClose from "../../../../../metrics/customHooks/useTrackTabClose";

const refinancingPurposeForSelect = [
  {
    id: 0,
    value: "repair",
    text: "Ремонт",
  },
  {
    id: 1,
    value: "buying_a_property",
    text: "Покупка недвижимости",
  },
  {
    id: 2,
    value: "buying_a_car",
    text: "Покупка автомобиля",
  },
  {
    id: 3,
    value: "repayment_of_loans",
    text: "Погашение кредитов",
  },
  {
    id: 4,
    value: "business_development",
    text: "Развитие бизнеса",
  },
  {
    id: 5,
    value: "treatment",
    text: "Лечение",
  },
  {
    id: 6,
    value: "other",
    text: "Другое",
  },
];

type propsType = {};

export const SBPDefineProcess: FC<propsType> = () => {
  // const [currentField, setCurrentField] = useState("");
  const [loanPurposeFocus, setLoanPurposeFocus] = useState(false);
  const [bankFocus, setBankFocus] = useState(false);
  const [errorMessageBank, setErrorMessageBank] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const secondPhoneRef = useRef<HTMLInputElement>(null);
  const innRef = useRef<HTMLInputElement>(null);
  const monthlyIncomeRef = useRef<HTMLInputElement>(null);
  const loanPurposeRef = useRef<HTMLInputElement>(null);
  const snilsRef = useRef<HTMLInputElement>(null);
  const bankSelectRef = useRef<HTMLInputElement>(null);

  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const { transformedDataForSelect, isLoading } = useGetBanks();
  const { data } = useGetInfoFromApplication();

  const form = useForm<any>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(SBPDefineProcessSchema),
    context: { clientPhone: data?.phone },
  });

  const loanDataAmount = useWatch({
    control: form.control,
    name: "loan_data.amount",
  });

  const innWatch = useWatch({
    control: form.control,
    name: "inn",
  });

  console.log(innWatch, "innWatch");

  const secondPhone = useWatch({
    control: form.control,
    name: "second_phone",
  });

  const monthlyIncome = useWatch({
    control: form.control,
    name: "monthly_income",
  });

  const snils = useWatch({
    control: form.control,
    name: "snils",
  });

  const loanPurpose = useWatch({
    control: form.control,
    name: "loan_purpose",
  });

  const bankId = useWatch({
    control: form.control,
    name: "bank_id",
  });

  const [selectWatch, bankIdWatch] = useWatch({
    control: form.control,
    name: ["select", "bank_id"],
  });

  const isAllFieldsFilled = !!(
    secondPhone && innWatch && monthlyIncome && loanPurpose && (bankId || selectWatch?.text)
  );

  const { videoCallPlannedDate } = useClock(form);

  // let innLength = false;
  // let validInn = null;
  // if (innWatch && innWatch?.length < 12) {
  //   innLength = true;
  // } else if (innWatch && innWatch?.length === 12) {
  //   validInn = innWatch && validateInn(undefined ? "" : innWatch);
  // }

  const loadingSendApplication = useSelector(
    processLoading.get(applicationSagaActions.sendApplication.type)
  );

  const routeLoading = useSelector(processLoading.get("routing"));

  const loanDataTerm = useWatch({
    control: form.control,
    name: "loan_data.loan_term",
  });

  const { keyPressHandler, setCurrentField } = useChangeFocus(
    [
      secondPhoneRef,
      innRef,
      monthlyIncomeRef,
      loanPurposeRef,
      snilsRef,
      bankSelectRef,
    ],
    isAllFieldsFilled
  );

  const handleConfirm = (data: any) => {
    setLoading(true);
    let bank_id;
    if (data?.select?.text) {
      bank_id = data?.select?.id;
    }
    if (data?.bank_id) {
      bank_id = Number(data?.bank_id);
    }

    validateBank();

    API.main.application
      .defineSBPBank({
        applicationId,
        bank_id,
        // loan_data: {
        //   amount: loanDataAmount,
        //   loan_term: loanDataTerm.toString(),
        // },
        inn: innWatch,
        ...(snils && { snils: stringHelpers.clearString(snils) }),
        monthly_income: Number(stringHelpers.clearString(monthlyIncome)),
        trusted_person_phone: stringHelpers.clearString(secondPhone),
        video_call_start_date: videoCallPlannedDate,
        loan_purpose: loanPurpose.value,
      })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          // Проверяем ваш счет в СБП
          sendMetrics("bank account ok");
          sendMetrics("common-client_otpravleny-dop-dannye_common-app", "otpravleny-dop-dannye");
          // dispatch(applicationSagaActions.sendApplication());
          // history.push(routes.application.statuses.data_verification);
          res?.data?.data?.application_type === "repledge_filling"
            ? history.push(routes.application.refinancing)
            : history.push(routes.application.statuses.data_verification);
        }
      })
      .catch((e) => {
        setLoading(false);
        dispatch(
          sagaActions.error({
            response: e,
            handledStatuses: [400],
          })
        );
      });
  };

  //TODO Переписать на yup
  const validateBank = () => {
    if (!selectWatch && !bankIdWatch) {
      setErrorMessageBank("Поле обязательно к заполнению!");
    } else {
      setErrorMessageBank("");
    }
  };

  useEffect(() => {
    if (selectWatch?.text && bankIdWatch) {
      form.setValue("select", { text: "" });
    }
  }, [bankIdWatch]);

  useEffect(() => {
    if (bankIdWatch && selectWatch?.text) {
      form.setValue("bank_id", null);
    }
  }, [selectWatch]);

  useEffect(() => {
    form.setValue(
      "loan_data.amount",
      Number(lsController.get("amount")) || 500000
    );
    form.setValue(
      "loan_data.loan_term",
      Number(lsController.get("period_month")) || 48
    );
  }, []);

  const attachAnotherCard = () => {
    dispatch(
      applicationSagaActions.createCard({
        redirectTo: routes.application.statuses.payment_system_response,
      })
    );
  };

  function getBankId(bankName: string): string | undefined {
    const bank = transformedDataForSelect.find((item) =>
      item?.text?.includes(bankName)
    );

    return bank?.id?.toString();
  }

  const AlfaBankId = getBankId("Альфа-Банк");
  const UralSibBankId = getBankId("Банк Уралсиб");
  const TinkoffBankId = getBankId("ТБанк");
  const Sber = getBankId("Сбербанк");
  const VTB = getBankId("ВТБ");
  const Sovkombank = getBankId("Совкомбанк");

  const debouncedValue = useDebounce(secondPhone, 1000);

  useEffect(() => {
    if (secondPhone?.replace(/\D/g, "") === data?.phone) {
      form.setError("second_phone", {
        type: "custom",
        message: "Введите второй номер телефона",
      });
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT == "stage") {
      form.setValue("inn", "362504340253");
    } else {
      form.setValue(
        "inn",
        data?.inn_auto_defined ? data?.auto_defined_inn : data?.inn
      );
    }
  }, [data]);

  useEffect(() => {
    sendMetrics("foto_car_ok");
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT == "stage") {
      form.setValue("second_phone", "+7 901 923 64 98");
      form.setValue("monthly_income", "100000");
      form.setValue("snils", "126-029-036 24");
      form.setValue("loan_purpose", {
        id: 0,
        text: "Ремонт",
        value: "repair",
      });
    }
  }, []);

  useTrackTabClose("common-client_leave-LK_common-app", "leave-LK");

  const onBlurSecondPhoneHandler = () => {
    if (secondPhone?.replace(/\D/g, "") === data.phone) {
      form.setError("second_phone", {
        type: "custom",
        message: "Введите второй номер телефона",
      });
    }
  };

  // const keyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.code === "Enter") {
  //     if (!isAllFieldsFilled) {
  //       e.preventDefault();
  //
  //       switch (currentField) {
  //         case "second_phone":
  //           innRef.current && innRef.current.focus();
  //           break;
  //         case "inn":
  //           monthlyIncomeRef.current && monthlyIncomeRef.current.focus();
  //           break;
  //         case "monthly_income":
  //           setLoanPurposeFocus(true);
  //           break;
  //         case "snils":
  //           setBankFocus(true);
  //           break;
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    loanPurpose?.value && snilsRef.current && snilsRef.current.focus();
  }, [loanPurpose?.value]);

  if (isLoading) {
    return (
      <MobilePageContainer>
        <Loader variant={"large"} color={"purple"} className={styles.loader} />
      </MobilePageContainer>
    );
  }

  return (
    <MobilePageContainer>
      <SmartForm form={form} submit={handleConfirm}>
        <RoundContainer className={styles.second_phone}>
          <div className={styles.title_second_phone}>
            Укажите второй номер телефона
          </div>
          <div className={styles.subtitle_second_phone}>
            Номер телефона родственника или друга
          </div>
          <InputNumber
            support={<Icons.ui.PencilBlack />}
            variant={"large"}
            inputMode={"tel"}
            defaultValue={"+79"}
            format={"+# ### ### ## ##"}
            name={"second_phone"}
            noNumericString
            label={"Номер телефона"}
            maxLength={16}
            autoFocus
            className={styles.phone_input}
            onBlur={onBlurSecondPhoneHandler}
            onFocus={() => setCurrentField("second_phone")}
            onKeyDown={keyPressHandler}
            inputElementRef={secondPhoneRef}
          />
          <div className={styles.note}>
            <div className={styles.icon}>
              <Icons.ui.Exclamation />
            </div>
            <div>Мы не будем звонить сейчас. Используется в крайнем случае</div>
          </div>
        </RoundContainer>
        <InputNumber
          support={
            data?.inn || data?.auto_defined_inn ? (
              <div className={styles.icon_lock}>
                <Icons.ui.Lock />
              </div>
            ) : (
              <Icons.ui.PencilBlack />
            )
          }
          // innLength={innLength}
          // validInn={validInn !== null ? validInn : true}
          name={"inn"}
          label={"Ваш ИНН"}
          maxLength={12}
          disabled={data?.inn || data?.auto_defined_inn}
        />

        <InputNumber
          inputMode={"numeric"}
          support={<Icons.ui.Ruble data-class={"dim"} />}
          thousandsGroupStyle={"thousand"}
          label={"Ваш средний месячный доход"}
          name={"monthly_income"}
          min={0}
          max={1000000}
          maxLength={9}
          inputElementRef={monthlyIncomeRef}
          onFocus={() => setCurrentField("monthly_income")}
          onKeyDown={keyPressHandler}
        />

        <Select
          name={"loan_purpose"}
          control={form.control}
          setValue={form.setValue}
          defaultValue={"Ремонт"}
          options={refinancingPurposeForSelect}
          selectTitle={"На что будете тратить средства?"}
          support={<Icons.ui.DropDown />}
          setFocus={loanPurposeFocus}
          inputElementRef={loanPurposeRef}
        />

        <InputText
          mask={"999-999-999 99"}
          label={"Ваш СНИЛС"}
          name={"snils"}
          inputMode={"numeric"}
          inputElementRef={snilsRef}
          onFocus={() => setCurrentField("snils")}
          onKeyDown={keyPressHandler}
        />

        <div className={styles.optional_snils}>
          Поле необязательно для заполнения
        </div>

        <div className={styles.container}>
          <RoundContainer className={styles.ignorePadding}>
            <div className={styles.popularBanks}>
              <div className={styles.title}>Выберите банк</div>
              <div className={styles.subtitle}>
                Выберите банк, в котором у вас подключен СБП. <br /> Счет должен
                быть открыт на ваше имя
              </div>
              <Radio
                // error={form.formState.bank_id.errors}
                name={"bank_id"}
                value={AlfaBankId}
                iconPosition={"right"}
                type={"radio"}
                iconClassName={styles.radioIcon}
                label={
                  <div className={styles.popularBank}>
                    <Icons.logos.AlphaBank className={styles.bankIcon} />
                    <div className={styles.bankLabel}>Альфа Банк</div>
                  </div>
                }
              />

              <Radio
                name={"bank_id"}
                value={Sber}
                iconPosition={"right"}
                type={"radio"}
                iconClassName={styles.radioIcon}
                label={
                  <div className={styles.popularBank}>
                    <Icons.logos.SberBank className={styles.bankIcon} />
                    <div className={styles.bankLabel}>Сбер</div>
                  </div>
                }
              />

              <Radio
                name={"bank_id"}
                value={TinkoffBankId}
                iconPosition={"right"}
                type={"radio"}
                iconClassName={styles.radioIcon}
                label={
                  <div className={styles.popularBank}>
                    <Icons.logos.TinkoffBank className={styles.bankIcon} />
                    <div className={styles.bankLabel}>ТБанк</div>
                  </div>
                }
              />

              <Radio
                name={"bank_id"}
                value={VTB}
                iconPosition={"right"}
                type={"radio"}
                iconClassName={styles.radioIcon}
                label={
                  <div className={styles.popularBank}>
                    <Icons.logos.VTBBank className={styles.bankIcon} />
                    <div className={styles.bankLabel}>ВТБ</div>
                  </div>
                }
              />

              <Radio
                name={"bank_id"}
                value={Sovkombank}
                iconPosition={"right"}
                type={"radio"}
                iconClassName={styles.radioIcon}
                label={
                  <div className={styles.popularBank}>
                    <Icons.logos.SovkomBank className={styles.bankIcon} />
                    <div className={styles.bankLabel}>Совкомбанк</div>
                  </div>
                }
              />
            </div>
            <Select
              name={"select"}
              control={form.control}
              setValue={form.setValue}
              options={transformedDataForSelect}
              selectTitle={"Другой банк"}
              search
              support={<Icons.ui.DropDown />}
              // setFocus={bankFocus}
              inputElementRef={bankSelectRef}
            />
          </RoundContainer>
          <div className={styles.clock}>
            <div className={styles.title}>Выберите удобное время</div>
            <div className={styles.subtitle}>
              Позвоним и проверим, что займ берёте именно Вы
            </div>
            <div className={styles.subtitle_small}>
              Укажите время по московскому часовому поясу
              {/*<div className={styles.link}>*/}
              {/*  Изменить часовой пояс*/}
              {/*</div>*/}
            </div>
            <div style={{ width: "100%", marginTop: 14 }}>
              <Clock form={form} />
            </div>
          </div>
          <Button
            className={styles.confirmButton}
            onClick={form.handleSubmit(handleConfirm)}
            loading={loading || loadingSendApplication || routeLoading}
          >
            Продолжить
          </Button>
          <div className={styles.error_message}>{errorMessageBank}</div>

          {/*<Button*/}
          {/*  className={styles.anotherCardButton}*/}
          {/*  variant={"underline"}*/}
          {/*  onClick={attachAnotherCard}*/}
          {/*>*/}
          {/*  Прикрепить другую карту*/}
          {/*</Button>*/}
          {/*<SBPSupportBlock />*/}
          {/*<FillingAssistance />*/}
        </div>
      </SmartForm>
    </MobilePageContainer>
  );
};
