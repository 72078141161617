import * as yup from "yup";
import { validationsMessages } from "../../../../../helpers/validation/validationsMessages";
import { validateInn } from "../../../../../helpers/inn";

export const SBPDefineProcessSchema = yup.object().shape({
  second_phone: yup
    .string()
    .required(validationsMessages.requiredField)
    .min(16, validationsMessages.requiredField)
    .test(
      "is-valid-second-phone",
      validationsMessages.secondPhone,
      (value, context) => {
        return (
          value?.replace(/\D/g, "") !== context.options.context?.clientPhone
        );
      }
    ),
  monthly_income: yup
    .string()
    // .required(validationsMessages.requiredField)
    .min(0, validationsMessages.min)
    .max(1000000, validationsMessages.max),
  // inn: yup
  //   .string()
  //   .test("is-valid-inn", validationsMessages.inn, (value) => {
  //     if (!value) return false;
  //     return validateInn(value);
  //   })
  //   .nullable(),
});
