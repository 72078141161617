/* UI */
import { ReactComponent as ArrowGreenInCircle } from "./ui/arrowGreenInCircle.svg";
import { ReactComponent as BigFingerUp } from "./ui/bigFingerUp.svg";
import { ReactComponent as BigFingerUpGreen } from "./ui/bigFingerUpGreen.svg";
import { ReactComponent as Bluebell } from "./ui/bluebell.svg";
import { ReactComponent as CheckmarkGreenBg } from "./ui/checkmarkGreenBg.svg";
import { ReactComponent as CheckmarkPurpleBg } from "./ui/checkmarkPurpleBg.svg";
import { ReactComponent as BlackCrossGreyBg } from "./ui/BlackCrossGreyBg.svg";
import { ReactComponent as LoaderGreyBg } from "./ui/LoaderGreyBg.svg";
import { ReactComponent as LockIcon } from "./ui/LockIcon.svg";
import { ReactComponent as NotFoundMagnifier } from "./ui/notFoundMagnifier.svg";
import { ReactComponent as Refinancing } from "./ui/Refinancing.svg";
import { ReactComponent as Plus } from "./ui/Plus.svg";
import { ReactComponent as Upload } from "./ui/Upload.svg";
import { ReactComponent as Information } from "./ui/Information.svg";
// import { ReactComponent as CrossBlackInCircle } from "./ui/crossBlackInCircle.svg";
import { ReactComponent as CrossRedInGrayCircle } from "./ui/crossRedInGrayCircle.svg";
import { ReactComponent as DropDown } from "./ui/dropDown.svg";
import { ReactComponent as CheckmarkNoPadding } from "./ui/checkmarkNoPadding.svg";
import { ReactComponent as Cross } from "./ui/cross.svg";
// import { ReactComponent as CrossBlack } from "./ui/crossBlack.svg";
import { ReactComponent as CrossBlackInPurpleCircle } from "./ui/crossBlackInPurpleCircle.svg";
import { ReactComponent as CrossSmallBlack } from "./ui/crossSmallBlack.svg";
import { ReactComponent as CrossWarning } from "./ui/crossWarning.svg";
import { ReactComponent as CrossWhite } from "./ui/crossWhite.svg";
// import { ReactComponent as Cross_warning_password } from "./ui/crossWarningPassword.svg";
import { ReactComponent as PencilBlack } from "./ui/pencilBlack.svg";
import { ReactComponent as PencilPurple } from "./ui/pencilPurple.svg";
import { ReactComponent as PencilNewPhone } from "./ui/PencilNewPhone.svg";
import { ReactComponent as Preloader } from "./ui/preloader.svg";
import { ReactComponent as Eye } from "./ui/eye.svg";
import { ReactComponent as ClosedEye } from "./ui/close_eye.svg";
import { ReactComponent as Lock } from "./ui/lock.svg";
import { ReactComponent as Ruble } from "./ui/ruble.svg";
import { ReactComponent as RubleWhite } from "./ui/ruble_white.svg";
import { ReactComponent as Checkbox_checked } from "./ui/checkbox_checked.svg";
import { ReactComponent as Checkbox_unchecked } from "./ui/checkbox_unchecked.svg";
import { ReactComponent as Calendar } from "./ui/calendar.svg";
import { ReactComponent as arrowIcon } from "./ui/Arrow.svg";
import { ReactComponent as arrowAngleIcon } from "./ui/ArrowAngle.svg";
import { ReactComponent as RUSSIA } from "./ui/RUSSIA.svg";
import { ReactComponent as ErrorExclamation } from "./ui/errorExclamation.svg";
import { ReactComponent as Burger } from "./ui/burger.svg";
import { ReactComponent as Loader } from "./ui/loader.svg";
import { ReactComponent as Logout } from "./ui/logout.svg";
import { ReactComponent as UndoArrow } from "./ui/undoArrow.svg";
import { ReactComponent as Hourglass } from "./ui/hourglass.svg";
import { ReactComponent as TransparentRoundCheckmark } from "./ui/TransparentRoundCheckmark.svg";
import { ReactComponent as Camera } from "./ui/camera.svg";
import { ReactComponent as CameraTransparentBg } from "./ui/camera_transparent_bg.svg";
import { ReactComponent as Checkmark } from "./ui/checkmark.svg";
import { ReactComponent as CheckmarkGreen } from "./ui/checkmark_green.svg";
import { ReactComponent as CheckmarkTransparentGreenBg } from "./ui/checkmark_transparent_green_bg.svg";
import { ReactComponent as MessageBoxLoading } from "./ui/messageBoxLoading.svg";
import { ReactComponent as CautionTriangle } from "./ui/CautionTriangle.svg";
import { ReactComponent as Stacks } from "./ui/stacks.svg";
import { ReactComponent as CheckmarkInCircle } from "./ui/checkmarkInCircle.svg";
import { ReactComponent as CheckmarkWithLoader } from "./ui/checkmarkWithLoader.svg";
import { ReactComponent as Money } from "./ui/money.svg";
import { ReactComponent as EmptyCircle } from "./ui/emptyCircle.svg";
import { ReactComponent as Pdf } from "./ui/pdf.svg";
import { ReactComponent as PdfLogo } from "./ui/pdfLogo.svg";
import { ReactComponent as PdfLogoPurple } from "./ui/pdfLogoPurple.svg";
import { ReactComponent as ArrowHeader } from "./ui/ArrowHeader.svg";
import { ReactComponent as Polygon } from "./ui/Polygon.svg";
import { ReactComponent as Polygon22 } from "./ui/Polygon22.svg";
import { ReactComponent as Triangle } from "./ui/triangle.svg";
import { ReactComponent as PhoneBlack } from "./ui/PhoneBlack.svg";
import { ReactComponent as Person } from "./ui/Person.svg";
import { ReactComponent as Bank } from "./ui/Bank.svg";
import { ReactComponent as ErrorTriangle } from "./ui/ErrorTriangle.svg";
import { ReactComponent as Document_noContainer } from "./ui/Document.svg";
import { ReactComponent as Radio_checked } from "./ui/Radio_checked.svg";
import { ReactComponent as Radio_unchecked } from "./ui/Radio_unchecked.svg";
import { ReactComponent as Checkmark_password } from "./ui/checkmark_password.svg";
// import { ReactComponent as Cross_warning_password } from "./ui/cross_warning_password.svg";
// import { ReactComponent as Drop_down } from "./ui/Drop_down.svg";
import { ReactComponent as StatusError } from "./ui/StatusError.svg";
import { ReactComponent as Exclamation } from "./ui/exclamation.svg";
import { ReactComponent as RedExclamation } from "./ui/redExclamation.svg";
import { ReactComponent as UndoArrowBlue } from "./ui/undoArrowBlue.svg";
import { ReactComponent as Checked } from "./ui/checked.svg";
import { ReactComponent as WhatsApp } from "./ui/WhatsApp.svg";
import { ReactComponent as WhatsAppGreen } from "./ui/WhatsAppGreen.svg";
import { ReactComponent as TransparentRoundCheckmarkBlue } from "./ui/TransparentRoundCheckmarkBlue.svg";
import { ReactComponent as LinkToPC } from "./ui/LinkToPC.svg";
import { ReactComponent as CheckmarkWhiteCircle } from "./ui/Checkmark_white_circle.svg";
import { ReactComponent as photoCameraWhite } from "./ui/photoCameraWhite.svg";
import { ReactComponent as HandShake } from "./ui/HandShake.svg";
import { ReactComponent as CameraBlue } from "./ui/CameraBlue.svg";
import { ReactComponent as SquareWithSlash } from "./ui/squareWithSlash.svg";
import { ReactComponent as Settings } from "./ui/Settings.svg";

/* LOGOS */
import { ReactComponent as Creddy } from "./logos/creddy.svg";
import { ReactComponent as CreddyViolet } from "./logos/creddyViolet.svg";
import { ReactComponent as CreddyLogo } from "./logos/creddy_logo.svg";
import { ReactComponent as LogoViolet } from "./logos/Logo_violet.svg";
import { ReactComponent as Phone } from "./logos/phone.svg";
import { ReactComponent as Telegram } from "./logos/telegram.svg";
import { ReactComponent as Whatsapp } from "./logos/whatsapp.svg";
import { ReactComponent as Whatsapp_white } from "./logos/whatsapp_white.svg";
import { ReactComponent as CreddyLarge } from "./logos/creddy_large.svg";
import { ReactComponent as AlphaBank } from "./logos/AplhaBank.svg";
import { ReactComponent as TinkoffBank } from "./logos/TinkoffBank.svg";
import { ReactComponent as VTBBank } from "./logos/VTBBank.svg";
import { ReactComponent as UralsibBank } from "./logos/UralsibBank.svg";
import { ReactComponent as OtkritieBank } from "./logos/OtkritieBank.svg";
import { ReactComponent as SberBank } from "./logos/SberBank.svg";
import { ReactComponent as SovkomBank } from "./logos/SovkomBank.svg";

/* CABINET */
import { ReactComponent as Card } from "./cabinet/card.svg";
import { ReactComponent as Check } from "./cabinet/check.svg";
import { ReactComponent as Document } from "./cabinet/document.svg";
import { ReactComponent as Exit } from "./cabinet/exit.svg";
import { ReactComponent as Info } from "./cabinet/info.svg";
import { ReactComponent as History } from "./cabinet/history.svg";
import { ReactComponent as Ruble2 } from "./cabinet/ruble.svg";
import { ReactComponent as Support } from "./cabinet/support.svg";
import { ReactComponent as InfoWithCircle } from "./cabinet/info_with_circle.svg";
import { ReactComponent as DocumentWithCircle } from "./cabinet/documentWithCircle.svg";
import { ReactComponent as dropDownArrow } from "./cabinet/dropDownArrow.svg";
import { ReactComponent as dropDownArrowWhite } from "./cabinet/dropDownArrowWhite.svg";
import { ReactComponent as Dollars } from "./cabinet/dollars.svg";
import { ReactComponent as CalendarCabinet } from "./cabinet/calendar.svg";
import { ReactComponent as ExclamationMark } from "./cabinet/exclamation.svg";

/* Loader */
import { ReactComponent as LoaderName } from "./Loader/loaderName.svg";
import { ReactComponent as LoaderImg } from "./Loader/loader.svg";
import { ReactComponent as LoaderTriangle } from "./Loader/loaderTriangle.svg";
import { ReactComponent as LoaderTriangleNext } from "./Loader/loaderTriangleNext.svg";

/* Info */
import { ReactComponent as InfoIcon } from "./info/infoIcon.svg";

export const Icons = {
  ui: {
    Upload,
    Information,
    Refinancing,
    Plus,
    LockIcon,
    LoaderGreyBg,
    NotFoundMagnifier,
    BlackCrossGreyBg,
    Settings,
    ArrowGreenInCircle,
    CameraBlue,
    HandShake,
    photoCameraWhite,
    BigFingerUp,
    BigFingerUpGreen,
    Bluebell,
    CheckmarkGreenBg,
    CheckmarkPurpleBg,
    // CrossBlackInCircle,
    CrossRedInGrayCircle,
    DropDown,
    CheckmarkNoPadding,
    Cross,
    // CrossBlack,
    CrossBlackInPurpleCircle,
    CrossSmallBlack,
    CrossWarning,
    CrossWhite,
    // Cross_warning_password,
    LinkToPC,
    TransparentRoundCheckmarkBlue,
    Checked,
    UndoArrowBlue,
    RedExclamation,
    Exclamation,
    StatusError,
    // BigFingerUp,
    // Drop_down,
    // Cross_warning_password,
    Checkmark_password,
    Triangle,
    Polygon22,
    Polygon,
    ArrowHeader,
    arrowIcon,
    arrowAngleIcon,
    Eye,
    Calendar,
    Camera,
    CameraTransparentBg,
    Checkmark,
    CheckmarkGreen,
    CheckmarkTransparentGreenBg,
    Checkbox_checked,
    Checkbox_unchecked,
    ClosedEye,
    PencilBlack,
    PencilPurple,
    PencilNewPhone,
    Preloader,
    Lock,
    Ruble,
    RubleWhite,
    RUSSIA,
    ErrorExclamation,
    Burger,
    Loader,
    Logout,
    UndoArrow,
    Hourglass,
    TransparentRoundCheckmark,
    MessageBoxLoading,
    CautionTriangle,
    Stacks,
    CheckmarkInCircle,
    CheckmarkWithLoader,
    EmptyCircle,
    Money,
    Pdf,
    PdfLogo,
    PdfLogoPurple,
    PhoneBlack,
    Person,
    Bank,
    ErrorTriangle,
    Document_noContainer,
    Radio_checked,
    Radio_unchecked,
    WhatsApp,
    WhatsAppGreen,
    CheckmarkWhiteCircle,
    SquareWithSlash,
  },
  logos: {
    Creddy,
    CreddyViolet,
    OtkritieBank,
    CreddyLogo,
    LogoViolet,
    Phone,
    Telegram,
    Whatsapp,
    Whatsapp_white,
    CreddyLarge,
    AlphaBank,
    SberBank,
    SovkomBank,
    TinkoffBank,
    UralsibBank,
    VTBBank,
  },
  cabinet: {
    ExclamationMark,
    Card,
    Document,
    History,
    Ruble2,
    Support,
    Exit,
    Info,
    Check,
    InfoWithCircle,
    DocumentWithCircle,
    dropDownArrow,
    dropDownArrowWhite,
    Dollars,
    CalendarCabinet,
  },
  loader: {
    LoaderName,
    LoaderImg,
    LoaderTriangle,
    LoaderTriangleNext,
  },
  info: {
    InfoIcon,
  },
};
