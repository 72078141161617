import { PayloadAction } from "@reduxjs/toolkit";
import { getCodeType } from "../../../api/auth/types";
import { call, select } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { API } from "../../../api";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { processStateController } from "../../util/helpers/processStateController";
import { isMobile } from "react-device-detect";
import { applicationApi } from "../../../api/main/application";
import { StateType } from "../../../store";
import { sagaApiType } from "../../util/types";
import { sendMetrics } from "../../../metrics";

export function* getCodeSaga({
  payload,
  type: actionType,
}: PayloadAction<getCodeType>) {
  const process = processStateController(actionType);

  try {
    const isFromSms: boolean = yield select(
      (state: StateType) => state.userReducer.isFromSms
    );
    yield process.start();
    if (!isMobile) {
      yield redirect(routes.auth.to_mobile_device);
      return;
    }

    /*
      Флаг isFromSms нужен чтобы не кидать пост код
      если мы кинем пост код, код из квери параметров перестанет подходить
     */
    if (!isFromSms) {
      const response: sagaApiType = yield call(API.auth.code, payload);

      if (response.status === 201) {
        sendMetrics(
          "common-client_otpravlen-nomer-dlya-registracii_common-app",
          "otpravlen-nomer-dlya-registracii",
        );
      }
    }

    yield redirect(routes.auth.code + `/${payload.smsTemplate}`);
  } catch (e) {
    yield call(errorHandlerSaga, {
      response: e,
    });
  } finally {
    yield process.stop();
  }
}
