import React, { FC, useState } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { motion } from "framer-motion";
import { Icons } from "assets/icons";
import { RangeWithTitleVariant2 } from "../../../../../ui/RangeWithTitleVariant2";
import { SwitcherTriple } from "../../../../../ui/SwitcherTriple";
import { Loader } from "../../../../../ui/Loader";
import { Button } from "../../../../../ui/Button";
import { stringHelpers } from "../../../../../../helpers/string";

export const getMonthString = (num: any) => {
  if (num % 10 === 1 && num % 100 !== 11) {
    return "месяц";
  } else if (
    [2, 3, 4].includes(num % 10) &&
    ![12, 13, 14].includes(num % 100)
  ) {
    return "месяца";
  } else {
    return "месяцев";
  }
};

type propsType = {
  scheduleLoading?: boolean;
  approve_button?: boolean;
  monthly_payment?: number;
  amount?: number;
  loan_term?: number;
  className?: string;
  loading: boolean;
};

export const ConditionFioComponent: FC<propsType> = ({
  scheduleLoading,
  approve_button = true,
  monthly_payment,
  loading,
  className,
  children,
  amount,
  loan_term,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      className={cn(styles.container, {
        [`${className}`]: className,
      })}
      initial={{ height: 138 }}
      animate={{ height: isOpen ? 426 : 138 }}
    >
      <div className={styles.chosen_conditions}>
        <div>
          Вы выбрали{" "}
          {amount
            ? stringHelpers.transformMoneyValue(amount) + " ₽"
            : "500000 ₽"}{" "}
          <br /> на{" "}
          {loan_term
            ? loan_term + " " + getMonthString(loan_term)
            : "48 месяцев"}
        </div>
        <div
          className={cn(styles.dropdown, {
            [styles.dropdown_open]: isOpen,
          })}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className={styles.dropdown_container}>
            <div className={cn(styles.dropdown_text, {
              [styles.dropdown_text_open]: isOpen
            })}>
              {isOpen ? "Отменить" : "Изменить"}
            </div>
            <Icons.ui.DropDown />
          </div>
        </div>
      </div>
      {isOpen ? (
        <motion.div
        // variants={dropdownMenuAnimation}
        // initial={"close"}
        // animate={isOpen ? "open" : "close"}
        >
          {children ? (
            children
          ) : (
            <>
              <RangeWithTitleVariant2
                step={10000}
                min={80000}
                max={500000}
                title={"Сумма займа, ₽"}
                name={"loan_data.amount"}
                containerClass={styles.loan_amount}
              />
              <div className={styles.divider} />
              <SwitcherTriple
                name={"loan_data.loan_term"}
                outerLabel={"Срок займа"}
                values={[24, 36, 48]}
                innerLabels={["24 месяца", "36 месяцев", "48 месяцев"]}
                switchVariant={"huge_gender"}
                variant={"large"}
              />
            </>
          )}
        </motion.div>
      ) : (
        <div className={styles.divider_thin} />
      )}
      <div
        className={cn(styles.monthly_payment, {
          [styles.monthly_payment_open]: isOpen,
        })}
      >
        Ежемесячный платеж:{" "}
        <span>{monthly_payment ? monthly_payment + " ₽" : <Loader />}</span>
      </div>
      {isOpen && approve_button && (
        <Button
          className={styles.button}
          loading={loading}
          loadingText={"Формирование документов"}
        >
          Подтвердить
        </Button>
      )}
    </motion.div>
  );
};
