import React, { FC, SetStateAction, useEffect, useState } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { motion } from "framer-motion";
import { Icons } from "../../../assets/icons";
import { stringHelpers } from "../../../helpers/string";
import { applicationSagaActions } from "../../../sagas/application/actions";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { SmartForm } from "../../hocs/SmartForm";
import { Button } from "../Button";
import { SwitcherTriple } from "../SwitcherTriple";
import { RangeWithTitleVariant2 } from "../RangeWithTitleVariant2";
import { useGetSchedule } from "../../../hooks/api/useGetSchedule";
import { useDebounce } from "../../../hooks/logical/useDebounce";
import { useGetInfoFromApplication } from "../../../hooks/api/useGetInfoFromApplication";
import { useQuery } from "react-query";
import { API } from "../../../api";
import {
  applicationType,
  loanDataType,
  repledgeExternalLoanDataType,
} from "../../../types/application/types";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../store/reducers/application";
import { lsController } from "../../../store/auxiliary/localStorage";
import { sagaActions } from "../../../sagas/util/sagaActions";
import {
  getMaxSumFromOffers,
  getMaxTermFromOffers,
} from "../../pages/Application/pages/LoanDecision";
import { Loader } from "../Loader";
import { getMonthString } from "../../pages/Application/pages/FioAndGrz/ConditionFioComponent";

export function roundDownToNearestThousand(number: number) {
  const nearestLowerInteger = Math.floor(number);

  const thousands = Math.floor(nearestLowerInteger / 1000);

  const roundedDown = thousands * 1000;

  return roundedDown;
}

type propsType = {
  repledgeExternalLoanData?: repledgeExternalLoanDataType;
  isRepledge?: boolean;
  loanData: {
    amount: number;
    loan_term: string;
  };
  selectedLoanData: loanDataType;
  offers: Array<Omit<loanDataType, "daily_percent_rate">>;
  loading: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  className?: string;
  acceptButton?: boolean;
};

export const Conditions: FC<propsType> = ({
  repledgeExternalLoanData,
  isRepledge,
  loanData,
  acceptButton = true,
  className,
  selectedLoanData,
  offers,
  loading,
  isOpen,
  setIsOpen,
}) => {
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [pingLoading, setPingLoading] = useState(false);
  const [onChangeDisable, setOnChangeDisable] = useState(false);
  const [savedResponse, setSavedResponse] = useState("");
  const [isFormingDocuments, setIsFormingDocuments] = useState(false);

  const dispatch = useDispatch();
  const form = useForm();

  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const applicationType = useSelector(
    applicationReducerSelectors.getApplicationType
  );

  const loanAmount = useWatch({
    control: form.control,
    name: "loan_data.amount",
  });

  const loanTerm = useWatch({
    control: form.control,
    name: "loan_data.loan_term",
  });

  const [amount, loan_term, schedule_amount, schedule_loan_term] = useWatch({
    control: form.control,
    name: [
      "loan_data.amount",
      "loan_data.loan_term",
      "schedule_amount",
      "schedule_loan_term",
    ],
  });

  const settlementAccountAmount =
    Number(repledgeExternalLoanData?.amount_to_pay_for_loan) < 50000
      ? 50000
      : repledgeExternalLoanData?.amount_to_pay_for_loan;

  const maxTermFromOffers = getMaxTermFromOffers(offers);
  const maxSumFromOffers = getMaxSumFromOffers(offers);

  // const maxSumFromApplication =

  const [maxSum, setMaxSum] = useState(offers && maxSumFromOffers);
  const [minSum, setMinSum] = useState(0);
  // const [pledge, setPledge] = useState(false);

  // поменял знаки >= на >, < на <=, хз правильно или нет, крашилось при maxSum = minSum
  useEffect(() => {
    if (maxSumFromOffers > loanData?.amount) {
      return setMinSum(roundDownToNearestThousand(loanData?.amount));
    } else if (maxSumFromOffers <= loanData?.amount) {
      return setMinSum(roundDownToNearestThousand(maxSumFromOffers * 0.9));
    }
  }, []);

  // const setMaxApprovedAmount = (): number => {
  //   switch (loanTerm) {
  //     case 24:
  //       return offers.find((item) => item.loan_term === "24")?.amount || 500000;
  //     case 36:
  //       return offers.find((item) => item.loan_term === "36")?.amount || 500000;
  //     case 48:
  //       return offers.find((item) => item.loan_term === "48")?.amount || 500000;
  //     default:
  //       return 500000;
  //   }
  // };

  const getSchedule = async () => {
    try {
      setScheduleLoading(true);

      const response = await API.main.application.getSchedule({
        applicationId: applicationId,
        amount: loanAmount,
        loan_term: loanTerm,
      });
      setSavedResponse(response?.data?.data?.monthly_payment);
    } catch (e) {
      console.log(e);
    } finally {
      setScheduleLoading(false);
    }
  };

  const disabledScheduleAmount =
    (amount >= roundDownToNearestThousand(minSum) && amount <= maxSum) ||
    loan_term >= maxTermFromOffers;

  const debouncedValue = useDebounce(
    {
      schedule_amount: loanAmount,
      schedule_loan_term: loanTerm,
      enabled: { value: disabledScheduleAmount },
    },
    500
  );

  const { schedule, isLoading } = useGetSchedule(debouncedValue);

  const { data, isLoading: isAppLoading } = useQuery(
    "conditions",
    () => API.main.application.getApplication({ applicationId }),
    {
      refetchInterval: 2000,
      enabled: applicationType === "generate_loan_contract_process",
      onSuccess: (data: any) => {
        // setLoanData(data.data.data.loan_data);
        // console.log(data.data.data.loan_data);
        const application: applicationType = data?.data?.data;
        lsController.set(
          "amount",
          String(application?.selected_loan_data?.amount)
        );
        // этот диспатч делался для бургера, скорее всего не нужен
        dispatch(
          applicationReducer.actions.setApplicationType({
            application_type: application.application_type,
          })
        );
        dispatch(
          applicationReducer.actions.setSelectedLoanData({
            amount: application.selected_loan_data?.amount,
          })
        );
      },
    }
  );

  const application: applicationType = data?.data?.data;

  const { data: applicationData } = useGetInfoFromApplication();

  const onChange = () => {
    setOnChangeDisable(true);
  };

  const submit = () => {
    setPingLoading(true);
    setIsFormingDocuments(true);

    API.main.application
      .sendingLoanTerms({
        applicationId,
        amount: loanAmount,
        loan_term: String(loanTerm),
      })
      .then((res) => {
        dispatch(
          applicationReducer.actions.setApplicationType({
            application_type: res.data.data.application_type,
          })
        );
        dispatch(
          applicationReducer.actions.setSelectedLoanData({
            amount: res.data.data.selected_loan_data?.amount,
          })
        );
      })
      .catch((e) => {
        setPingLoading(false);
        dispatch(sagaActions.error({ response: e }));
      })
      .finally(() => {
        setIsFormingDocuments(false);
      });
  };

  useEffect(() => {
    if (!pingLoading && !isFormingDocuments) {
      setIsOpen(false);
    }
  }, [pingLoading, isFormingDocuments, setIsOpen]);

  useEffect(() => {
    form.setValue("loan_data.amount", Number(selectedLoanData.amount));
    form.setValue("loan_data.loan_term", Number(selectedLoanData.loan_term));
  }, [selectedLoanData, schedule_amount, schedule_loan_term]);

  useEffect(() => {
    if (!selectedLoanData.loan_term && !selectedLoanData.amount) {
      form.setValue(
        "loan_data.amount",
        Number(lsController.get("amount")) || 500000
      );
      form.setValue(
        "loan_data.loan_term",
        Number(lsController.get("period_month")) || 48
      );
    }
  }, []);

  useEffect(() => {
    if (pingLoading && applicationType !== "generate_loan_contract_process") {
      setPingLoading(false);
    }
  }, [applicationType]);

  useEffect(() => {
    !isLoading && setOnChangeDisable(false);
  }, [isLoading]);

  // const amountFrom48Term = offers
  //   ?.filter((item) => {
  //     return item.loan_term === "48";
  //   })
  //   .reduce((acc, rec) => {
  //     return rec?.amount;
  //   }, 0);
  // const amountFrom36Term = offers
  //   ?.filter((item) => {
  //     return item.loan_term === "36";
  //   })
  //   .reduce((acc, rec) => {
  //     return rec?.amount;
  //   }, 0);
  // const amountFrom24Term = offers
  //   ?.filter((item) => {
  //     return item.loan_term === "24";
  //   })
  //   .reduce((acc, rec) => {
  //     return rec?.amount;
  //   }, 0);

  // useEffect(() => {
  //   if (!offers) return;
  //
  //   let newMaxSum;
  //
  //   switch (loan_term) {
  //     case 48:
  //       newMaxSum = amountFrom48Term;
  //       break;
  //     case 36:
  //       newMaxSum = amountFrom36Term;
  //       break;
  //     case 24:
  //       newMaxSum = amountFrom24Term;
  //       break;
  //     default:
  //       newMaxSum = null;
  //   }
  //
  //   if (newMaxSum !== null) {
  //     setMaxSum(newMaxSum);
  //     if (amount > newMaxSum) {
  //       form.setValue("loan_data.amount", newMaxSum);
  //     }
  //   }
  // }, [
  //   loan_term,
  //   offers,
  //   amount,
  //   amountFrom48Term,
  //   amountFrom36Term,
  //   amountFrom24Term,
  // ]);

  // const settlementAccountAmount =
  //   Number(application?.repledge_external_loan_data?.amount_to_pay_for_loan) >
  //   amount
  //     ? 0
  //     : roundDownToNearestThousand(
  //         application?.repledge_external_loan_data
  //           ?.amount_to_pay_for_loan as number
  //       );

  const lowAmount = amount >= 50000 && amount < 80000;

  const offersLoanTerm = offers?.find((item) => item.loan_term)?.loan_term;

  const monthRangeDataHigh = [
    Number(offersLoanTerm) === 48 ? 47 : Number(offersLoanTerm),
    48,
  ];

  const monthRangeDataLow = [3, 12];

  useEffect(() => {
    if (lowAmount && loan_term > 12) {
      form.setValue("loan_data.loan_term", 12);
    } else if (!lowAmount && loan_term <= 12) {
      form.setValue("loan_data.loan_term", Number(offersLoanTerm));
    }
  }, [amount]);

  const maxRange = () => {
    if (Number(offersLoanTerm) === 48) {
      return 49;
    } else if (lowAmount && !isRepledge) {
      return 12;
    } else {
      return 48;
    }
  };

  const minRange = () => {
    if (Number(offersLoanTerm) === 48) {
      return 47;
    } else if (lowAmount || isRepledge) {
      return 3;
    } else {
      return Number(offersLoanTerm);
    }
  };

  const conditionForLockRangeAmount =
    maxSum === 50000 ||
    (maxSum === 80000 && isRepledge) ||
    (maxSum === repledgeExternalLoanData?.amount_to_pay_for_loan && isRepledge);

  const conditionForLockRangeLoanTerms = Number(offersLoanTerm) === 48;

  const amountError =
    amount < roundDownToNearestThousand(minSum) || amount > maxSum;

  const minSumRender = () => {
    if (isRepledge) {
      if (repledgeExternalLoanData?.amount_to_pay_for_loan !== maxSum) {
        return settlementAccountAmount;
      } else {
        return 499000;
      }
    } else {
      if (maxSum === 50000) {
        return 49000;
      } else if (maxSum === 80000 && isRepledge) {
        return 79000;
      } else if (minSum < 50000 && maxSum !== 50000) {
        return 50000;
      } else if (minSum < 80000 && Number(offersLoanTerm) >= 13) {
        return 80000;
      } else {
        return minSum;
      }
    }
  };

  const rangeDataRender = () => {
    if (isRepledge) {
      return [3, 48];
    } else if (lowAmount) {
      return monthRangeDataLow;
    } else {
      return monthRangeDataHigh;
    }
  };

  // useEffect(() => {
  //   if (!offers) return;
  //
  //   if (amount < minSum) {
  //     form.setValue("loan_data.amount", roundDownToNearestThousand(minSum));
  //   }
  // }, [amount, offers, minSum]);

  // useEffect(() => {
  //   applicationData?.repledge && setPledge(true);
  // }, [applicationData?.repledge]);

  return (
    <SmartForm submit={submit} form={form}>
      <motion.div
        className={cn(styles.container, {
          [`${className}`]: className,
          // [styles.container_pledge]: pledge && !isOpen,
        })}
        initial={{ height: 128 }}
        animate={{ height: isOpen && !isFormingDocuments ? 426 : 128 }}
      >
        {isRepledge ? (
          <>
            <div className={styles.condition_container}>
              {/*<div onClick={() => setPledge((prev) => !prev)}>switchPledge</div>*/}
              <div
                className={styles.dropdown_wrapper}
              >
                <div
                  className={styles.dropdown_container}
                  onClick={() => {
                    if (!pingLoading && !isFormingDocuments) setIsOpen(!isOpen);
                  }}
                >
                  <div className={cn(styles.dropdown_text, {
                    [styles.dropdown_text_open]: isOpen
                  })}>
                    {isOpen ? "Отменить" : "Изменить"}
                  </div>
                  <div
                    className={cn(styles.dropdown, {
                      [styles.dropdown_open_pledge]: isRepledge,
                      [styles.dropdown_open]:
                      isOpen && !pingLoading && !isFormingDocuments,
                      [styles.dropdown_disabled]:
                      isOpen && pingLoading && isFormingDocuments,
                    })}
                  >
                    <Icons.ui.DropDown />
                  </div>
                </div>
              </div>
              <div className={styles.condition_flex_container}>
                <div className={styles.title_condition}>Одобрено:</div>
                <div className={styles.value_condition}>
                  {stringHelpers.transformMoneyValue(amount)} ₽ на{" "}
                  {/*{stringHelpers.transformMoneyValue(*/}
                  {/*  application?.selected_loan_data?.amount*/}
                  {/*) ||*/}
                  {/*  stringHelpers.transformMoneyValue(*/}
                  {/*    selectedLoanData.amount*/}
                  {/*  )}{" "}*/}
                  {/*₽ на{" "}*/}
                  {(application?.selected_loan_data?.loan_term &&
                    application?.selected_loan_data?.loan_term +
                      " " +
                      getMonthString(
                        application?.selected_loan_data?.loan_term
                      )) ||
                    selectedLoanData.loan_term +
                      " " +
                      getMonthString(selectedLoanData.loan_term)}
                </div>
              </div>
              <div className={styles.condition_flex_container}>
                <div className={styles.title_condition}>На руки:</div>
                <div className={styles.value_condition}>
                  {/*{stringHelpers.transformMoneyValue(*/}
                  {/*  application?.selected_loan_data?.amount*/}
                  {/*) ||*/}
                  {/*  stringHelpers.transformMoneyValue(*/}
                  {/*    selectedLoanData.amount*/}
                  {/*  )}{" "}*/}
                  {(repledgeExternalLoanData?.amount_to_pay_for_loan &&
                    stringHelpers.transformMoneyValue(
                      (
                        amount -
                        repledgeExternalLoanData?.amount_to_pay_for_loan
                      ).toFixed(2)
                    )) ?? <Loader />}{" "}
                  ₽
                </div>
              </div>
              <div className={styles.condition_flex_container}>
                <div className={styles.title_condition}>
                  Погашение текущего займа:
                </div>
                <div className={styles.value_condition}>
                  {/*{stringHelpers.transformMoneyValue(*/}
                  {/*  application?.selected_loan_data?.amount*/}
                  {/*) ||*/}
                  {/*  stringHelpers.transformMoneyValue(*/}
                  {/*    selectedLoanData.amount*/}
                  {/*  )}{" "}*/}
                  {stringHelpers.transformMoneyValue(
                    repledgeExternalLoanData?.amount_to_pay_for_loan
                  ) || <Loader />}{" "}
                  ₽
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {/*<div*/}
            {/*  style={{ marginBottom: 10 }}*/}
            {/*  onClick={() => setPledge((prev) => !prev)}*/}
            {/*>*/}
            {/*  switchPlege*/}
            {/*</div>*/}
            <div className={styles.chosen_conditions}>
              <div>
                Одобрено{" "}
                <span>
                  {stringHelpers.transformMoneyValue(
                    application?.selected_loan_data?.amount
                  ) ||
                    stringHelpers.transformMoneyValue(
                      selectedLoanData.amount
                    )}{" "}
                  ₽
                </span>
                <br />
                на{" "}
                {(application?.selected_loan_data?.loan_term &&
                  application?.selected_loan_data?.loan_term +
                    " " +
                    getMonthString(
                      application?.selected_loan_data?.loan_term
                    )) ||
                  selectedLoanData.loan_term +
                    " " +
                    getMonthString(selectedLoanData.loan_term)}
              </div>
              <div
                className={cn(styles.dropdown, {
                  [styles.dropdown_open]:
                    isOpen && !pingLoading && !isFormingDocuments,
                  [styles.dropdown_disabled]:
                    isOpen && pingLoading && isFormingDocuments,
                })}
                onClick={() => {
                  if (!pingLoading && !isFormingDocuments) setIsOpen(!isOpen);
                }}
              >
                <div className={styles.dropdown_container}>
                  <div className={cn(styles.dropdown_text, {
                    [styles.dropdown_text_open]: isOpen
                  })}>
                    {isOpen ? "Отменить" : "Изменить"}
                  </div>
                  <Icons.ui.DropDown />
                </div>
              </div>
            </div>
          </>
        )}

        {isOpen ? (
          <motion.div
          // variants={dropdownMenuAnimation}
          // initial={"close"}
          // animate={isOpen ? "open" : "close"}
          >
            <div className={styles.range_container}>
              <RangeWithTitleVariant2
                maxLength={7}
                amount={amount}
                amountError={!isRepledge && amountError}
                conditionForLockRangeAmount={conditionForLockRangeAmount}
                step={1000}
                min={minSumRender() as number}
                max={
                  maxSum === 50000 || (maxSum === 80000 && isRepledge)
                    ? maxSum + 1000
                    : maxSum
                }
                // max={setMaxApprovedAmount()}
                title={""}
                name={"loan_data.amount"}
                containerClass={styles.loan_amount}
                onChange={onChange}
                getSchedule={getSchedule}
                disabled={
                  isLoading || scheduleLoading || offers[0]?.amount === 50000
                }
                loading={isLoading || scheduleLoading}
              />
              <div className={styles.divider} />
              <RangeWithTitleVariant2
                amountForTextInformation={amount}
                textInformation={!isRepledge && true}
                maxLength={2}
                conditionForLockRangeLoanTerms={conditionForLockRangeLoanTerms}
                period
                marks
                rangeData={rangeDataRender()}
                max={maxRange()}
                min={minRange()}
                title={""}
                onChange={onChange}
                disabled={
                  isLoading || scheduleLoading || offersLoanTerm === "48"
                }
                name={"loan_data.loan_term"}
                containerClass={styles.loan_amount}
                loading={isLoading || scheduleLoading}
              />
            </div>
            {/*<SwitcherTriple*/}
            {/*  name={"loan_data.loan_term"}*/}
            {/*  outerLabel={"Срок займа"}*/}
            {/*  values={[24, 36, 48]}*/}
            {/*  innerLabels={["24 месяца", "36 месяцев", "48 месяцев"]}*/}
            {/*  switchVariant={"huge_gender"}*/}
            {/*  variant={"large"}*/}
            {/*  onChange={onChange}*/}
            {/*  disabled={isLoading}*/}
            {/*  offers={offers}*/}
            {/*/>*/}
          </motion.div>
        ) : (
          !isRepledge && <div className={styles.divider_thin} />
        )}
        <div
          className={cn(styles.monthly_payment, {
            [styles.monthly_payment_pledge]: isRepledge,
          })}
        >
          <div>Ежемесячный платеж:</div>
          <div>
            {scheduleLoading || isLoading ? (
              <Icons.ui.Loader className={styles.loaderIcon} />
            ) : (
              <span>
                {schedule?.monthly_payment
                  ? stringHelpers.transformMoneyValue(schedule?.monthly_payment)
                  : savedResponse}{" "}
                ₽
              </span>
            )}
          </div>
        </div>
        {isOpen && acceptButton && (
          <Button
            disabled={
              amount > maxSum || amount < roundDownToNearestThousand(minSum)
            }
            className={styles.button}
            loading={pingLoading}
            loadingText={"Формирование документов"}
          >
            Подтвердить
          </Button>
        )}
      </motion.div>
    </SmartForm>
  );
};
