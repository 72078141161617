import { PayloadAction } from "@reduxjs/toolkit";
import { verifyApplicationType } from "../../../api/main/application/types";
import { processStateController } from "../../util/helpers/processStateController";
import { call, put } from "redux-saga/effects";
import { errorsReducer } from "../../../store/auxiliary/errorsReducer";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { sagaApiType } from "../../util/types";
import { API } from "../../../api";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { sendMetrics } from "../../../metrics";

export function* verifyApplicationSaga({
  payload,
  type: actionType,
}: PayloadAction<verifyApplicationType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    // POST /applying-agreement
    const response: sagaApiType = yield call(
      API.main.application.verifyApplication,
      payload
    );

    if (response.status === 200) {
      sendMetrics("code/loan");
      sendMetrics("common-client_zayavka-podpisana_common-app", "zayavka-podpisana");
    }

    yield redirect(routes.application.statuses.sent_and_scoring_process);
  } catch (e: any) {
    if (e?.response?.status == 404) {
      yield put(
        errorsReducer.actions.setFieldsErrors({
          code: "Неверный код!",
        })
      );
    } else {
      yield call(errorHandlerSaga, {
        response: e,
      });
    }
  } finally {
    yield process.stop();
  }
}
