import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { useForm, useWatch } from "react-hook-form";
import { Icons } from "assets/icons";
import { SmartForm } from "../../../../hocs/SmartForm";
import { Button } from "../../../../ui/Button";
import { InputNumber } from "../../../../ui/Inputs/InputNumber";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import {
  carBodyType,
  carType,
} from "../../../../../store/reducers/application/types";
import { useDispatch, useSelector } from "react-redux";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { useGetCar } from "../../../../../hooks/api/useGetCar";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { authSagaActions } from "../../../../../sagas/auth/actions";
import { AuthHeader } from "../../../Auth/components/AuthHeader";
import { BannerGreen } from "../../../../ui/BannerGreen";
import {
  constantStrings,
  linksToDocumentsOnStatic,
} from "../../../../../helpers/init/constantStrings";
import { FillingAssistance } from "../../../../ui/FillingAssistance";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { routes } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { Checkbox } from "../../../../ui/Checkbox";
import { sendMetrics } from "../../../../../metrics";

type propsType = {
  width?: number;
};

type formType = {
  phone_number: string;
  car?: carType;
};

export const CarCheckingPhone: FC<propsType> = ({ ...props }) => {
  const dispatch = useDispatch();

  const [isChecked1, setIsChecked1] = useState(true);

  const history = useHistory();

  const loading = useSelector(processLoading.get(authSagaActions.getUser.type));

  const loadingCode = useSelector(
    processLoading.get(authSagaActions.getCode.type)
  );
  const form = useForm<formType>({
    reValidateMode: "onSubmit",
    // resolver: yupResolver(scheme),
  });

  const phone = useWatch({
    control: form.control,
    name: "phone_number",
  });

  if (phone?.length === 0) {
    form.setValue("phone_number", "+7");
  }

  // const car: carType = useSelector(applicationReducerSelectors.getCar);
  // const carBody: carBodyType = useSelector(
  //   applicationReducerSelectors.getCarBody
  // );

  // const applicationId = useSelector(
  //   applicationReducerSelectors.getApplicationId
  // );

  // const downloadFile = (documentType: string) => {
  //   dispatch(
  //     applicationSagaActions.downloadFile({
  //       applicationId: applicationId,
  //       documentType: documentType,
  //     })
  //   );
  // };

  // const { carData } = useGetCar({
  //   vin: vin ? vin : car?.vin,
  //   reg_number: grz ? grz : car?.reg_number,
  // });

  // const grz = lsController.get("grz");

  const submit = (data: formType) => {
    dispatch(authSagaActions.getUser({ phone: data.phone_number }));
  };

  useEffect(() => {
    sendMetrics(
      "common-client_start-avtorizacii-registracii_common-app",
      "start-avtorizacii-registracii",
    );
  }, []);

  return (
    <>
      <MobilePageContainer className={styles.form}>
        <>
          <SmartForm form={form} submit={submit}>
            <div className={styles.auth_block_title}>Регистрация</div>
            <div className={styles.auth_block_text}>
              Введите Ваш номер телефона, <br />
              привязанный к СБП
            </div>
            <div className={styles.auth_block_phone}>
              <InputNumber
                support={<Icons.ui.PencilBlack />}
                variant={"large"}
                inputMode={"tel"}
                defaultValue={"+7"}
                format="+# ### ### ## ##"
                name="phone_number"
                noNumericString
                label="Номер телефона"
                maxLength={16}
                autoFocus
                className={styles.phone_input}
              />
            </div>
            <div className={styles.button}>
              <Button
                loading={loading || loadingCode}
                disabled={phone?.length !== 16 || !isChecked1}
              >
                Продолжить
              </Button>
            </div>
            <div
              className={styles.link_lc}
              onClick={() => history.push(routes.auth.personal_cabinet)}
            >
              <div className={styles.link_text}>
                У меня уже есть личный кабинет
              </div>
            </div>
            <div className={styles.subtitle}>
              Нажимая кнопку{" "}
              <span className={styles.font_weight}>“Продолжить”,</span> <br />{" "}
              Вы даете своё согласие на обработку <br /> персональных данных
            </div>
            {/*{props?.width && props?.width < 1023 && (*/}
            <div className={styles.container_footer}>
              <div className={styles.notes_block}>
                <Checkbox
                  name={"pd"}
                  className={styles.mr_check_box}
                  onChange={() => setIsChecked1((prev) => !prev)}
                  checked={isChecked1}
                  blockValue={true}
                />
                <div>
                  <a
                    target={"_blank"}
                    href={linksToDocumentsOnStatic.consentPd}
                  >
                    <Icons.ui.PdfLogo />
                  </a>
                </div>
                <div className={styles.notes_block_text}>
                  <a
                    target={"_blank"}
                    href={linksToDocumentsOnStatic.consentPd}
                  >
                    {" "}
                    Согласие на обработку персональных данных
                  </a>
                </div>
              </div>
            </div>
            {/*)}*/}
          </SmartForm>
        </>
      </MobilePageContainer>
    </>
  );
};
