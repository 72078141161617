import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.css";
import {
  errorsReducer,
  errorsSelectors,
} from "../../../../../store/auxiliary/errorsReducer";
import { useCodeFieldLogic } from "../../../../ui/CodeField/useCodeFieldLogic";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { applicationReducer, applicationReducerSelectors } from "../../../../../store/reducers/application";
import { API } from "../../../../../api";
import { CodeField } from "../../../../ui/CodeField";
import { Button } from "../../../../ui/Button";
import { Title } from "../../../../ui/Title";
import { Subtitle } from "../../../../ui/Subtitle";
import { useHistory, useParams } from "react-router-dom";
import { userSaleApi } from "../../../../../api/main/user_sale";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { useQuery } from "react-query";
import { applicationType } from "types/application/types";
import { Loader } from "components/ui/Loader";
import { Icons } from "../../../../../assets/icons";
import { routes } from "../../../../../routes";
import { StateType } from "../../../../../store";
import { linksToDocumentsOnStatic } from "../../../../../helpers/init/constantStrings";
import { FillingAssistance } from "../../../../ui/FillingAssistance";
import { Checkbox } from "../../../../ui/Checkbox";
import { SmartForm } from "../../../../hocs/SmartForm";
import { useForm, useWatch } from "react-hook-form";
import { stringHelpers } from "../../../../../helpers/string";
import { Conditions } from "../../../../ui/Conditions";
import cn from "classnames";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { CodeFieldOneInput } from "../../../../ui/CodeFieldOneInput";
import useTrackTabClose from "../../../../../metrics/customHooks/useTrackTabClose";
import { sendMetrics } from "../../../../../metrics";

interface CheckboxData {
  name: string;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: () => void;
  text: string;
  disabled?: boolean;
}

export const Code: FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isChecked1, setIsChecked1] = useState(true);
  const [isChecked2, setIsChecked2] = useState(true);
  const [isChecked3, setIsChecked3] = useState(true);
  const [isChecked4, setIsChecked4] = useState(true);
  const [isCheckedDepositContract, setIsCheckedDepositContract] =
    useState(true);
  const [isCheckedLoanContract, setIsCheckedLoanContract] = useState(true);
  const [isCheckedRiskNotification, setIsCheckedRiskNotification] =
    useState(true);
  const [isApplyingAgreementSecond, setIsApplyingAgreementSecond] =
    useState(true);
  const [isCheckedOrder, setIsCheckedOrder] = useState(true);

  const [testLoading, setTestLoading] = useState(false);

  const disabled = testLoading || isDropdownOpen;

  const form = useForm();

  const history = useHistory();
  const dispatch = useDispatch();
  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );
  const makeRepeatResend = useSelector(
    applicationReducerSelectors.getMakeRepeatResend
  );
  const loading = useSelector(
    processLoading.get(applicationSagaActions.verifyApplication.type)
  );
  const loading2 = useSelector(
    processLoading.get(applicationSagaActions.signLoanContract.type)
  );
  const errors = useSelector(errorsSelectors.fieldsErrors);
  const selectedLoanData = useSelector(
    applicationReducerSelectors.getSelectedLoanData
  );

  const { variant } = useParams<{
    variant: "loan" | "contract";
  }>();
  const { data, isLoading } = useQuery(
    "code",
    () => API.main.application.getApplication({ applicationId }),
    {
      enabled: applicationId !== null,
      onSuccess: (data: any) => {
        // setLoanData(data.data.data.loan_data);
        // console.log(data.data.data.loan_data);
        const application: applicationType = data?.data?.data;
        // этот диспатч делался для бургера, скорее всего не нужен
        // dispatch(
        //   applicationReducer.actions.setApplicationType({
        //     application_type: application.application_type,
        //   })
        // );
        dispatch(
          applicationReducer.actions.setSelectedLoanData({
            amount: application.selected_loan_data?.amount,
          })
        );
      },
    }
  );

  const application: applicationType = data?.data?.data;

  const isRepledge: any = application?.repledge;

  const code: `${number}` | undefined = useWatch({
    control: form.control,
    name: "code",
  });

  const codeField = useCodeFieldLogic({
    codeLength: 4,
    onChange: () => {
      dispatch(errorsReducer.actions.removeFieldError("code"));
    },
  });

  const confirm = () => {
    switch (variant) {
      case "loan":
        dispatch(
          applicationSagaActions.verifyApplication({
            code: code!,
            applicationId,
          })
        );
        break;
      case "contract":
        // post loan-contract
        dispatch(
          applicationSagaActions.signLoanContract({
            code: code!,
            applicationId,
          })
        );
        break;
    }
  };

  const resend = () => {
    switch (variant) {
      case "loan":
        API.main.application.repeatCode({
          applicationId,
        });
        break;
      case "contract":
        // На повторный код - тот же запрос, что на первое получение
        userSaleApi.code({
          applicationId,
        });
        break;
    }
  };

  // * если юзер релоадид страницу, то я должен отправить запрос на resend кода
  // * для этого у нас есть поле в стор
  useEffect(() => {
    if (makeRepeatResend && applicationId) {
      resend();
    }
  }, [applicationId]);

  const downloadFile = (documentType: string) => {
    dispatch(
      applicationSagaActions.downloadFile({
        applicationId: applicationId,
        documentType: documentType,
      })
    );
  };

  const phoneNumber = useSelector(
    (state: StateType) => state.userReducer.phone
  );

  const renderCheckbox = (checkboxData: CheckboxData) => (
    <div className={styles.notes_block}>
      <Checkbox
        name={checkboxData.name}
        className={styles.mr_check_box}
        onChange={() => checkboxData.setIsChecked((prev) => !prev)}
        checked={checkboxData.isChecked}
        blockValue={true}
        disabled={disabled}
      />
      <div
        className={cn(styles.notes_block_contract_text, {
          [styles.checked]: isChecked3,
          [styles.disabled]: disabled,
        })}
      >
        <a target={"_blank"}>
          <Icons.ui.PdfLogo />
        </a>
      </div>
      <div className={styles.notes_block_text}>
        <a target={"_blank"} onClick={checkboxData.onClick}>
          {checkboxData.text}
        </a>
      </div>
    </div>
  );

  const hasRiskNotificationDocument = application?.documents?.some(
    (document: any) => document.document_type === "risk_notification"
  );

  const checkboxesContract: CheckboxData[] = [
    {
      name: "pd",
      isChecked: isCheckedLoanContract,
      setIsChecked: setIsCheckedLoanContract,
      onClick: () => downloadFile("loan_contract"),
      text: "Индивидуальные условия договора микрозайма",
      disabled,
    },
    {
      name: "aa",
      isChecked: isCheckedDepositContract,
      setIsChecked: setIsCheckedDepositContract,
      onClick: () => downloadFile("deposit_contract"),
      text: "Условия договора обеспечения исполнения обязательств заемщика (залогодателя) по договору потребительского микрозайма",
      disabled,
    },
    {
      name: "applying_agreement_second",
      isChecked: isApplyingAgreementSecond,
      setIsChecked: setIsApplyingAgreementSecond,
      onClick: () => downloadFile("applying_agreement_second"),
      text: "Анкета-заявление на выдачу потребительского микрозайма физическому лицу",
      disabled,
    },
  ];

  if (hasRiskNotificationDocument) {
    checkboxesContract.push({
      name: "rn",
      isChecked: isCheckedRiskNotification,
      setIsChecked: setIsCheckedRiskNotification,
      onClick: () => downloadFile("risk_notification"),
      text: "Уведомление о существующих рисках",
      disabled,
    });
  }

  if (application?.repledge) {
    checkboxesContract.push({
      name: "or",
      isChecked: isCheckedOrder,
      setIsChecked: setIsCheckedOrder,
      onClick: () => downloadFile("order"),
      text: "Распоряжение о перечислении в счет погашения задолженности",
      disabled,
    });
  }

  const areAllCheckboxesChecked = [
    isChecked1,
    isChecked2,
    isChecked3,
    isChecked4,
    isCheckedDepositContract,
    isCheckedLoanContract,
    isCheckedRiskNotification,
    isApplyingAgreementSecond,
    isCheckedOrder,
  ].every(Boolean);

  const amount = lsController.get("amount");

  useEffect(() => {
    if (variant === "loan") {
      sendMetrics(
        "common-client_ehkran-podpisaniya-zayavki_common-app",
        "ehkran-podpisaniya-zayavki"
      );
    } else {
      sendMetrics("common-client_finalnoe-odobrenie_common-app", "finalnoe-odobrenie");
    }
  }, []);

  useTrackTabClose("common-client_leave-LK_common-app", "leave-LK", variant);

  // * тут нужно поменять текста в зависимости от variant
  return (
    <>
      <MobilePageContainer className={styles.form}>
        {isLoading || applicationId === null ? (
          <Loader
            variant={"large"}
            color={"purple"}
            className={styles.loader}
          />
        ) : (
          <>
            {variant === "contract" && (
              <Conditions
                repledgeExternalLoanData={
                  application?.repledge_external_loan_data
                }
                isRepledge={isRepledge}
                loanData={application?.loan_data}
                offers={application.offers}
                selectedLoanData={application?.selected_loan_data}
                loading={testLoading}
                isOpen={isDropdownOpen}
                setIsOpen={setIsDropdownOpen}
              />
            )}
            <div
              className={cn(styles.container, {
                [styles.disabled]: disabled,
              })}
            >
              <Title disabled={disabled} className={styles.title}>
                {variant === "loan" ? "Заявление на займ" : "Подпишите договор"}
              </Title>
              {variant === "loan" && (
                <Subtitle className={styles.subtitle}>
                  Подпишите документы на выдачу займа. <br />
                  Мы всё проверим и пришлём СМС
                </Subtitle>
              )}

              {variant === "contract" && (
                <Subtitle className={styles.sum} disabled={disabled}>
                  На сумму{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {stringHelpers.transformMoneyValue(selectedLoanData?.amount) || (
                      amount && stringHelpers.transformMoneyValue(amount)
                    )}{" "}₽
                  </span>
                </Subtitle>
              )}

              <div
                className={cn(styles.auth_block_code, {
                  [styles.disabled]: disabled,
                })}
              >
                <div>
                  {stringHelpers.phoneNormalizeSecondVariant(phoneNumber)}
                </div>
              </div>
              <SmartForm submit={() => console.log()} form={form}>
                <div className={styles.code}>
                  <CodeFieldOneInput
                    // control={form.control}
                    disabled={disabled}
                    sendCodeHandler={resend}
                    retryCount={codeField.retryCount}
                    setRetryCount={codeField.setRetryCount}
                  />
                </div>
              </SmartForm>
              {/*<CodeField*/}
              {/*  {...codeField}*/}
              {/*  sendCodeHandler={resend}*/}
              {/*  error={errors?.code}*/}
              {/*  disabled={disabled}*/}
              {/*  className={styles.codeField}*/}
              {/*  retryCount={codeField.retryCount}*/}
              {/*  setRetryCount={codeField.setRetryCount}*/}
              {/*/>*/}
              <div
                className={cn(styles.retry_count, {
                  [styles.disabled]: disabled,
                })}
              >
                Попытка {codeField.retryCount} из 5
              </div>

              <Button
                disabled={!code || code.length < 4 || !areAllCheckboxesChecked}
                onClick={confirm}
                className={styles.button}
                loading={loading || loading2}
              >
                {variant === "loan" ? "Подписать" : "Продолжить"}
              </Button>
            </div>
            {variant === "loan" && (
              <>
                <SmartForm
                  form={form}
                  submit={() => console.log()}
                  className={styles.popularBanks}
                >
                  <div className={styles.notes_block}>
                    <Checkbox
                      name={"pd"}
                      className={styles.mr_check_box}
                      onChange={() => setIsChecked1((prev) => !prev)}
                      checked={isChecked1}
                      blockValue={true}
                    />
                    <div
                      className={cn(styles.notes_block_contract_text, {
                        [styles.checked]: isChecked1,
                        [styles.disabled]: disabled,
                      })}
                    >
                      <a
                        target={"_blank"}
                        onClick={() => downloadFile("personal_data_agreement")}
                      >
                        <Icons.ui.PdfLogo />
                      </a>
                    </div>
                    <div
                      className={cn(styles.notes_block_text, {
                        [styles.text_black]: isChecked1,
                        [styles.disabled]: disabled,
                      })}
                    >
                      <a
                        target={"_blank"}
                        onClick={() => downloadFile("personal_data_agreement")}
                      >
                        {" "}
                        Согласие на обработку персональных данных и запрос
                        кредитной истории
                      </a>
                    </div>
                  </div>

                  <div className={styles.notes_block}>
                    <Checkbox
                      name={"aa"}
                      className={styles.mr_check_box}
                      onChange={() => setIsChecked4((prev) => !prev)}
                      checked={isChecked4}
                      blockValue={true}
                    />
                    <div
                      className={cn(styles.notes_block_contract_text, {
                        [styles.checked]: isChecked4,
                        [styles.disabled]: disabled,
                      })}
                    >
                      <a
                        target={"_blank"}
                        onClick={() => downloadFile("applying_agreement")}
                      >
                        <Icons.ui.PdfLogo />
                      </a>
                    </div>
                    <div
                      className={cn(styles.notes_block_text, {
                        [styles.text_black]: isChecked4,
                        [styles.disabled]: disabled,
                      })}
                    >
                      <a
                        target={"_blank"}
                        onClick={() => downloadFile("applying_agreement")}
                      >
                        {" "}
                        Анкета-заявление на выдачу потребительского микрозайма
                        физическому лицу
                      </a>
                    </div>
                  </div>
                  <div className={styles.notes_block}>
                    <Checkbox
                      name={"inf"}
                      className={styles.mr_check_box}
                      onChange={() => setIsChecked2((prev) => !prev)}
                      checked={isChecked2}
                      blockValue={true}
                    />
                    <div
                      className={cn(styles.notes_block_contract_text, {
                        [styles.checked]: isChecked2,
                        [styles.disabled]: disabled,
                      })}
                    >
                      <a target={"_blank"} href={linksToDocumentsOnStatic.inf}>
                        <Icons.ui.PdfLogo />
                      </a>
                    </div>
                    <div
                      className={cn(styles.notes_block_text, {
                        [styles.text_black]: isChecked2,
                        [styles.disabled]: disabled,
                      })}
                    >
                      <a target={"_blank"} href={linksToDocumentsOnStatic.gt}>
                        {" "}
                        Ознакомлен с Общими условиями договора потребительского
                        микрозайма
                      </a>
                    </div>
                  </div>
                  <div className={styles.notes_block}>
                    <Checkbox
                      name={"rules"}
                      className={styles.mr_check_box}
                      onChange={() => setIsChecked3((prev) => !prev)}
                      checked={isChecked3}
                      blockValue={true}
                    />
                    <div
                      className={cn(styles.notes_block_contract_text, {
                        [styles.checked]: isChecked3,
                        [styles.disabled]: disabled,
                      })}
                    >
                      <a
                        target={"_blank"}
                        href={linksToDocumentsOnStatic.rules}
                      >
                        <Icons.ui.PdfLogo />
                      </a>
                    </div>
                    <div
                      className={cn(styles.notes_block_text, {
                        [styles.text_black]: isChecked3,
                        [styles.disabled]: disabled,
                      })}
                    >
                      <a
                        target={"_blank"}
                        href={linksToDocumentsOnStatic.rules}
                      >
                        {" "}
                        Ознакомлен с Правилами предоставления потребительских
                        микрозаймов
                      </a>
                    </div>
                  </div>
                </SmartForm>
              </>
            )}
            {variant === "contract" && (
              <SmartForm form={form} submit={() => console.log()}>
                {/*<div*/}
                {/*  className={cn(styles.notes_block, {*/}
                {/*    [styles.disabled]: disabled,*/}
                {/*  })}*/}
                {/*>*/}
                {checkboxesContract.map((checkboxData) => (
                  <React.Fragment key={checkboxData.name}>
                    {renderCheckbox(checkboxData)}
                  </React.Fragment>
                ))}
                {/*</div>*/}
              </SmartForm>
            )}
          </>
        )}
        {/*<Button onClick={() => setTestLoading(!testLoading)}>Change variant</Button>*/}
      </MobilePageContainer>
      {variant === "contract" && (
        <></>
        // <div className={styles.footer_container}>
        // <FillingAssistance className={styles.footer} />
        // </div>
      )}
    </>
  );
};
