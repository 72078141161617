import TagManager from "react-gtm-module";
import ym from "react-yandex-metrika";
import { applicationType } from "../types/application/types";
import { lsController } from "../store/auxiliary/localStorage";

export const sendMetrics = (ymValue: string, gtmValue?: string) => {
  if (process.env.REACT_APP_ENABLE_METRICS === "true") {
    if (gtmValue) {
      TagManager.dataLayer({
        dataLayer: {
          event: "trackEvent",
          eventCategory: "common-client",
          eventAction: gtmValue,
          eventLabel: "common-app",
        }
      });
    }

    ym("reachGoal", ymValue);
    console.log(`ym-target "${ymValue}" reached prod`);
    gtmValue && console.log(`gtm-target "${gtmValue}" reached prod`);
  } else {
    console.log(`js-target "${ymValue}" reached`);
  }
};

export const checkPhotoRecognition = (
  documents: applicationType["document_photos"]
) => {
  const docsWithoutSelfieWithPassport = documents.filter(
    (document) => document.photo_type !== "selfie_with_passport"
  );

  for (const document of docsWithoutSelfieWithPassport) {
    if (
      document.photo_type === "passport_main" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasPassportMainRecognized")) {
        sendMetrics("foto_passport_titul");
        lsController.set("wasPassportMainRecognized", true);
      }
    }
    if (
      document.photo_type === "passport_registration" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasPassportRegistrationRecognized")) {
        sendMetrics("foto_registration_recognize");
        lsController.set("wasPassportRegistrationRecognized", true);
      }
    }
    if (
      document.photo_type === "pts_front" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasPtsFrontRecognized")) {
        sendMetrics("pts_external_recognize");
        lsController.set("wasPtsFrontRecognized", true);
      }
    }
    if (
      document.photo_type === "pts_back" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasPtsBackRecognized")) {
        sendMetrics("pts_internal_recognize");
        lsController.set("wasPtsBackRecognized", true);
      }
    }
    if (
      document.photo_type === "vehicle_registration_certificate_front" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasStsFrontRecognized")) {
        sendMetrics("sts_auto_recognize");
        lsController.set("wasStsFrontRecognized", true);
      }
    }
    if (
      document.photo_type === "vehicle_registration_certificate_back" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasStsBackRecognized")) {
        sendMetrics("sts_client_recognize");
        lsController.set("wasStsBackRecognized", true);
      }
    }
    if (
      document.photo_type === "driver_license_front" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasDriverLicenseFrontRecognized")) {
        sendMetrics("vu_foto_recognize");
        lsController.set("wasDriverLicenseFrontRecognized", true);
      }
    }
    if (
      document.photo_type === "driver_license_back" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasDriverLicenseBackRecognized")) {
        sendMetrics("vu_categories_recognize");
        lsController.set("wasDriverLicenseBackRecognized", true);
      }
    }
  }
};
